import React from 'react';

function Chuchu(props) {
  return (
    <svg
      id="chuchu"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={props.width}
      height={props.height}
      viewBox="0 0 397.362 438.818">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#f59e45" />
          <stop offset="1" stopColor="#fd1313" />
        </linearGradient>
        <filter
          id="left_wing"
          x="0"
          y="159.41"
          width="153.362"
          height="172.177"
          filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.365" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="right_wing"
          x="244"
          y="167.41"
          width="153.362"
          height="172.177"
          filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-2" />
          <feFlood floodOpacity="0.365" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="linear-gradient-3"
          x1="0.5"
          y1="-0.015"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#e69806" />
          <stop offset="0.049" stopColor="#ffb61c" />
          <stop offset="0.113" stopColor="#fcd94a" />
          <stop offset="0.803" stopColor="#f9cf43" />
          <stop offset="1" stopColor="#ea964e" />
        </linearGradient>
        <filter
          id="body"
          x="54.937"
          y="0"
          width="293.839"
          height="353.741"
          filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-3" />
          <feFlood floodOpacity="0.353" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <linearGradient
          id="linear-gradient-4"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#b92400" />
          <stop offset="1" stopColor="#ff9343" />
        </linearGradient>
        <filter
          id="Ellipse_9"
          x="190.682"
          y="55.41"
          width="162"
          height="162"
          filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur-5" />
          <feFlood floodOpacity="0.627" />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="blur" x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="7" />
        </filter>
      </defs>
      <g id="Chuchu" className="Chuchu" transform="translate(-446.318 -337.59)">
        <ellipse
          id="shadow"
          cx="104"
          cy="29"
          rx="94"
          ry="25"
          transform="translate(542 700)"
          fill="#e6e6e6"
          opacity="0.4"
          filter="url(#blur)"
        />
        <g id="left_foot" className="left_foot" transform="translate(-357 -14)">
          <path
            id="Path_9"
            data-name="Path 9"
            d="M1671.751,693.331V724.6"
            transform="translate(-693 -2.989)"
            fill="none"
            stroke="#d36900"
            strokeLinecap="round"
            strokeWidth="10"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M1669.382,691.859v29.652"
            transform="translate(-693 -5)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeWidth="2"
            opacity="0.164"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            d="M1652,736.409c-1.679,6.167,8.439.429,13.491,3.562s3.707,9.178,6.725,9.178.929-6.045,5.349-9.178,13.663,2.606,12.324-3.562-17.681-21.108-17.681-21.108S1653.679,730.242,1652,736.409Z"
            transform="translate(-693 1)"
            fill="#d36900"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            d="M1654,739.537s6.275-1.482,10.252,0,5.658,7,5.658,7,.341-21.789-15.91-7"
            transform="matrix(0.999, -0.035, 0.035, 0.999, -717.867, 58.284)"
            opacity="0.112"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M1667.718,738.809s-5.41-1.272-8.839,0-4.879,6.008-4.879,6.008-.294-18.705,13.718-6.008"
            transform="translate(-760.752 238.849) rotate(-8)"
            opacity="0.112"
          />
        </g>
        <g
          id="right_foot"
          className="right_foot"
          transform="translate(-350 -14)">
          <path
            id="Path_18"
            data-name="Path 18"
            d="M1671.751,693.331V724.6"
            transform="translate(-650 -2.989)"
            fill="none"
            stroke="#d36900"
            strokeLinecap="round"
            strokeWidth="10"
          />
          <path
            id="Path_19"
            data-name="Path 19"
            d="M1669.382,691.859v29.652"
            transform="translate(-650 -5)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeWidth="2"
            opacity="0.164"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M1652,736.409c-1.679,6.167,8.439.429,13.491,3.562s3.707,9.178,6.725,9.178.929-6.045,5.349-9.178,13.663,2.606,12.324-3.562-17.681-21.108-17.681-21.108S1653.679,730.242,1652,736.409Z"
            transform="translate(-650 1)"
            fill="#d36900"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M1654,739.537s6.275-1.482,10.252,0,5.658,7,5.658,7,.341-21.789-15.91-7"
            transform="matrix(0.999, -0.035, 0.035, 0.999, -674.867, 58.284)"
            opacity="0.112"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M1667.718,738.809s-5.41-1.272-8.839,0-4.879,6.008-4.879,6.008-.294-18.705,13.718-6.008"
            transform="translate(-717.752 238.849) rotate(-8)"
            opacity="0.112"
          />
        </g>
        <g
          className="left_wing"
          transform="matrix(1, 0, 0, 1, 446.32, 337.59)"
          filter="url(#left_wing)">
          <path
            id="left_wing-2"
            data-name="left_wing"
            d="M1453.507,658.289s-76.19-54.156-93.764-85.854-16.409-68.323-16.409-68.323l135.346,43.305Z"
            transform="translate(-1334.32 -338.7)"
            fill="url(#linear-gradient)"
          />
        </g>
        <g
          className="right_wing"
          transform="matrix(1, 0, 0, 1, 446.32, 337.59)"
          filter="url(#right_wing)">
          <path
            id="right_wing-2"
            data-name="right_wing"
            d="M1368.491,658.289s76.19-54.156,93.764-85.854,16.409-68.323,16.409-68.323l-135.346,43.305Z"
            transform="translate(-1090.32 -330.7)"
            fill="url(#linear-gradient)"
          />
        </g>
        <g
          className="body"
          transform="matrix(1, 0, 0, 1, 446.32, 337.59)"
          filter="url(#body)">
          <path
            id="body-2"
            data-name="body"
            d="M1230.2,684.143c61.421,2.306,159.981-15.11,137.668-137.414s-109.921-165.5-109.921-165.5,7.01-8.79,20.366-5.462,17.087-12.367,17.087-12.367,5.408-14.168-26.743-14.8-51.957,32.625-51.957,32.625-2.819-34.488-21-29.128-3.809,34.838-3.809,34.838-50.609,17.894-88.494,144.482S1168.778,681.837,1230.2,684.143Z"
            transform="translate(-1031.32 -342.59)"
            fill="url(#linear-gradient-3)"
          />
        </g>
        <g id="left_eye" className="left_eye">
          <circle
            id="left-eye_white"
            cx="21"
            cy="21"
            r="21"
            transform="translate(591 465)"
            fill="#fff"
          />
          <circle
            id="left_eye_ball"
            cx="15.5"
            cy="15.5"
            r="15.5"
            transform="translate(597 476)"
          />
          <circle
            id="left_eye_shine"
            cx="5"
            cy="5"
            r="5"
            transform="translate(605 477)"
            fill="#fff"
          />
        </g>
        <g id="right_eye" className="right_eye">
          <circle
            id="right_eye_white"
            cx="21"
            cy="21"
            r="21"
            transform="translate(662 465)"
            fill="#fff"
          />
          <circle
            id="right_eye_ball"
            cx="15.5"
            cy="15.5"
            r="15.5"
            transform="translate(668 476)"
          />
          <circle
            id="right_eye_shine"
            cx="5"
            cy="5"
            r="5"
            transform="translate(679 477)"
            fill="#fff"
          />
        </g>
        <g
          id="beak"
          className="beak"
          onClick={props.onBeakClick ? props.onBeakClick : null}>
          <path
            id="Path_5"
            className="beak_bottom"
            data-name="Path 5"
            d="M1232.807,559.664l52.359-39.824H1187.55Z"
            transform="translate(-588 15)"
            fill="#c14300"
          />
          <path
            id="Path_4"
            className="beak_top"
            data-name="Path 4"
            d="M1187.17,521.112s23.733-13.123,46.963-13.123,51.168,11.153,51.168,11.153l-51.168,31.615Z"
            transform="translate(-588 16)"
            fill="url(#linear-gradient-4)"
          />
        </g>
        {props.glasses === false ? null : (
          <g id="glasses" className="glasses" transform="translate(280 240)">
            <g id="glasses_left" className="glasses_left">
              <circle
                id="Ellipse_11"
                data-name="Ellipse 11"
                cx="68"
                cy="68"
                r="68"
                transform="translate(230 177)"
                fill="#777"
                opacity="0.471"
              />
              <g
                id="Group_3"
                data-name="Group 3"
                transform="translate(-280 -233)">
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M1575.624,456.558l21.761,9.9"
                  transform="translate(-1050 -14)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="15"
                />
              </g>
              <g transform="matrix(1, 0, 0, 1, 166.32, 97.59)">
                <circle
                  cx="72"
                  cy="72"
                  r="67"
                  transform="translate(59.68 74.41)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="10"
                />
              </g>
              <path
                id="left_reflection"
                className="left_reflection"
                d="M1436.276,425.007l-63.582,104.009"
                transform="translate(-1123 -238)"
                fill="none"
                stroke="#fff"
                strokeWidth="5"
                opacity="0.139"
              />
            </g>
            <g id="glasses_right" className="glasses_right">
              <circle
                id="glass_right"
                cx="68"
                cy="68"
                r="68"
                transform="translate(370 172)"
                fill="#777"
                opacity="0.471"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M1597.386,456.558l-21.761,9.9"
                transform="translate(-1106 -252)"
                fill="none"
                stroke="#000"
                strokeWidth="15"
              />
              <g
                id="right_reflection"
                className="right_reflection"
                transform="translate(-280 -233)">
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M1436.276,425.007l-63.582,104.009"
                  transform="translate(-693)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="5"
                  opacity="0.139"
                />
                <path
                  id="Path_23"
                  data-name="Path 23"
                  d="M1436.276,425.007l-63.582,104.009"
                  transform="translate(-683 9)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="5"
                  opacity="0.139"
                />
              </g>
              <g transform="matrix(1, 0, 0, 1, 310, 97.59)">
                <circle
                  cx="72"
                  cy="72"
                  r="67"
                  transform="translate(59.68 74.41)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="10"
                />
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  );
}

export { Chuchu };
