import React from 'react';
import Styles from './svgAssets.module.scss';

function ThreeCircles(props) {
  return (
    <svg
      className={`${Styles.threeCircles} ${
        props.top ? Styles.top : Styles.bottom
      } ${props.left ? Styles.left : Styles.right} ${props.className}`}
      xmlns="http://www.w3.org/2000/svg"
      height="60px"
      width="270px"
      viewBox="0 0 270 60">
      <circle
        data-name="tc_shadow1"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(10 10)"
        fill="rgba(0, 0, 0, 0.2)"
      />
      <circle
        data-name="tc_c1"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(0 0)"
        fill="#fff"
      />
      <circle
        data-name="tc_shadow2"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(110 10)"
        fill="rgba(0, 0, 0, 0.2)"
      />
      <circle
        data-name="tc_c2"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(100 0)"
        fill="#fff"
      />
      <circle
        data-name="tc_shadow3"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(210 10)"
        fill="rgba(0, 0, 0, 0.2)"
      />
      <circle
        data-name="tc_c3"
        cx="22.5"
        cy="22.5"
        r="22.5"
        transform="translate(200 0)"
        fill="#fff"
      />
    </svg>
  );
}

export { ThreeCircles };
