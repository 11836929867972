import React, { useEffect } from 'react';
import Lottie from 'lottie-web';
import Styles from './loading.module.scss';
import loadingLottie from '../../assets/lotties/loading.json';

function Loading() {
  useEffect(() => {
    if (
      document.getElementById('loadingLottieContainer').children.length === 0
    ) {
      Lottie.loadAnimation({
        container: document.getElementById('loadingLottieContainer'),
        animationData: loadingLottie,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'benefit',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
      });
    }
  }, []);

  return (
    <div className={Styles.loadingContainer}>
      <div id="loadingLottieContainer" height={300} width={300} />
    </div>
  );
}

export { Loading };
