import { Link } from 'react-router-dom';
import EmailIcon from '../../assets/email-icon.png';
import InstaIcon from '../../assets/instagram-icon.svg';
import FacebookIcon from '../../assets/facebook-icon.svg';
import LinkedinIcon from '../../assets/linkedin-icon.svg';
import Styles from './footer.module.scss';

function Footer() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.contentContainer}>
        <div>
          <h4>THE YELLOW CHUCHU</h4>
          <div className={Styles.pagesContainer}>
            <Link to="/about-me">About Me</Link>
            <span>|</span>
            <Link to="/games">Games</Link>
            <span>|</span>
            <Link to="/shop">Shop</Link>
            {/* <span>|</span>
						<Link to="/printables">Printables</Link>
						<span>|</span>
						<Link to="/events">Events</Link> */}
          </div>
        </div>
        <div className={Styles.linksContainer}>
          <a href="mailto:theyellowchuchu@gmail.com">
            <img src={EmailIcon} alt="email" />
          </a>
          <a
            href="https://www.linkedin.com/company/the-yellow-chuchu/"
            target="_blank"
            rel="noreferrer">
            <img src={LinkedinIcon} alt="linkedin" />
          </a>
          <a
            href="https://www.facebook.com/people/The-Yellow-Chuchu/100089670949561/?mibextid=2JQ9oc"
            target="_blank"
            rel="noreferrer">
            <img src={FacebookIcon} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/theyellowchuchu/"
            target="_blank"
            rel="noreferrer">
            <img src={InstaIcon} alt="instagram" />
          </a>
        </div>
      </div>
      <p className="m-0 pb-2 pt-4 mt-2 w-100 text-center">
        Copyright &copy; The Yellow Chuchu. 2022
      </p>
    </div>
  );
}

export { Footer };
