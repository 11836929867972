/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from 'react-fontawesome';
import { connect } from 'react-redux';
import Styles from './cart.module.scss';
import { updateProductQuantity, removeFromCart } from '../../../store/actions';
import { Alert } from '../../../components/alert';
import { Loading } from '../../../components/loading';

function CartScreen(props) {
  const navigate = useNavigate();
  const productAssets = require.context(
    '../../../assets/shopAssets',
    true,
    /.*/,
  );
  const [cartSum, setCartSum] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cart } = props;
  const [cartAutoUpdated, setCartAutoUpdated] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    text: '',
    type: '',
  });

  useEffect(() => {
    setLoading(true);

    //	eslint-disable-next-line
    setCartSum(
      cart.products.reduce(
        (n, item) =>
          n + parseFloat(item.salePrice || item.price) * item.quantity,
        0,
      ),
    );

    setLoading(false);
    //	eslint-disable-next-line
  }, []);

  useEffect(() => {
    //	eslint-disable-next-line
    setCartSum(
      cart.products.reduce(
        (n, item) =>
          n + parseFloat(item.salePrice || item.price) * item.quantity,
        0,
      ),
    );
  }, [props, cart]);

  async function setQuantity(key, quantity) {
    await setAlert({ show: false, text: '', type: '' });

    setCartAutoUpdated(false);

    await props.updateProductQuantity({
      key,
      quantity,
    });

    setAlert({ show: true, text: 'Updated cart!', type: 'success' });
  }

  async function removeProductFromCart(itemKey) {
    await setAlert({ show: false, text: '', type: '' });

    setCartAutoUpdated(false);

    await props.removeFromCart(itemKey);

    setAlert({ show: true, text: 'Item removed from cart!', type: 'error' });
  }

  return (
    <div className={Styles.mainContainer}>
      {alert.show ? <Alert text={alert.text} type={alert.type} /> : null}
      {loading ? <Loading /> : null}
      <p
        className={Styles.shopLink}
        onClick={() => {
          navigate('/shop');
        }}>
        <FontAwesomeIcon name="chevron-left" style={{ marginRight: '10px' }} />{' '}
        SHOP
      </p>
      <h3 className={Styles.mainHeading}>YOUR CART</h3>
      <div className={Styles.cartItemsContainer}>
        <p
          className={`${Styles.cartUpdatedText} ${
            cartAutoUpdated ? 'visible' : 'hidden'
          }`}>
          {cartAutoUpdated}
        </p>
        <div className="w-100 d-flex justify-content-between">
          <p className={Styles.itemsTitle}>CART ITEMS</p>
          <p className={Styles.itemsTitle}>PRICE</p>
        </div>
        <hr className={Styles.line} />
        {cart.products.length ? (
          cart.products.map((cartItem) => {
            return (
              <div key={cartItem.key} className={Styles.cartItem}>
                <div className="d-flex">
                  <img
                    src={productAssets(`./${cartItem.key}/0.jpg`)}
                    alt="product"
                  />
                  <div className={Styles.productTextContainer}>
                    <p className={Styles.productInfo}>{cartItem.title}</p>
                    {cartItem.salePrice ? (
                      <p className={Styles.salePrice}>
                        Rs.{cartItem.salePrice} <span>Rs.{cartItem.price}</span>
                      </p>
                    ) : (
                      <p className={Styles.price}>Rs.{cartItem.price}</p>
                    )}
                    <div className={Styles.quantityContainer}>
                      <p
                        className={Styles.quantityCounter}
                        onClick={() => {
                          if (cartItem.quantity > 1) {
                            setQuantity(cartItem.key, cartItem.quantity - 1);
                          }
                        }}>
                        -
                      </p>
                      <p className={Styles.quantityText}>{cartItem.quantity}</p>
                      <p
                        className={Styles.quantityCounter}
                        onClick={() => {
                          if (cartItem.quantity < 5) {
                            setQuantity(cartItem.key, cartItem.quantity + 1);
                          } else {
                            setAlert({
                              show: true,
                              text: 'You can not add more than 5 items of this product to your cart!',
                              type: 'warning',
                            });
                          }
                        }}>
                        +
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="m-0">
                    RS.{' '}
                    {parseFloat(cartItem.salePrice || cartItem.price) *
                      cartItem.quantity}
                  </p>
                  <button
                    onClick={() => removeProductFromCart(cartItem.key)}
                    className={Styles.removeCartItem}>
                    Remove
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-muted p-5 mt-5">There are no items in your cart</p>
        )}
      </div>
      {cart.products.length ? (
        <>
          <div className={Styles.chargesContainer}>
            <div className="d-flex align-tems-center">
              <p>Order total:</p>
              <p className={`p-0 ${Styles.chargesPrice}`}>Rs. {cartSum}</p>
            </div>
            <div className="d-flex align-tems-center">
              <p>Shipping charges:</p>
              <p className={Styles.chargesPrice}>Rs. 200</p>
            </div>
            <hr className={Styles.line} />
            <div className={`d-flex align-tems-center ${Styles.totalPrice}`}>
              <p>Total:</p>
              <p className={`p-0 ${Styles.chargesPrice}`}>
                Rs. {cartSum + 200}
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              navigate('/checkout', {
                state: {
                  cartItems: props.cart,
                },
              });
            }}
            className={Styles.proceedBtn}>
            Proceed to Checkout
          </button>
        </>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  updateProductQuantity: (product) => dispatch(updateProductQuantity(product)),
  removeFromCart: (key) => dispatch(removeFromCart(key)),
});

const Cart = connect(mapStateToProps, mapDispatchToProps)(CartScreen);

export { Cart };
