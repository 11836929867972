import React, { Component } from 'react';
import axios from 'axios';
import Lottie from 'lottie-web';
import gsap from 'gsap';
import Styles from './contactUs.module.scss';
import { Chuchu } from '../../components/svgAssets/chuchuCharacter';
import { Home } from '../../components/svgAssets/home';
import confettiLottie from '../../assets/lotties/confetti.json';
import { Loading } from '../../components/loading';

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.name = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.message = React.createRef();

    this.state = {
      showSendButton: false,
      showLoading: false,
      invalidInputs: false,
    };
  }

  validateInputs() {
    if (
      this.name.current.value !== '' &&
      this.message.current.value !== '' &&
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.current.value,
      ) &&
      /^(([0-9]{7,11}))$/.test(this.phone.current.value)
    ) {
      return true;
    }
    return false;
  }

  hideSuccessMessage() {
    const successMessage = document.getElementById('emailSuccessMessage');
    const showSuccessMessage = gsap.timeline();

    showSuccessMessage
      .from(successMessage, 0, { scale: '1' })
      .to(successMessage, 0.5, { scale: '0', ease: 'back.out(1.7)' });
  }

  componentDidMount() {
    if (
      document.getElementById('contactUsLottieContainer') &&
      document.getElementById('contactUsLottieContainer').children.length === 0
    ) {
      Lottie.loadAnimation({
        container: document.getElementById('contactUsLottieContainer'),
        animationData: confettiLottie,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'benefit',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
      });
    }
  }

  sendEmail(e) {
    e.preventDefault();

    if (this.validateInputs()) {
      this.setState({
        showLoading: true,
        invalidInputs: false,
      });

      const date = new Date().toLocaleString('en-PK');

      axios
        .post(
          `${process.env.REACT_APP_SMS_ALERT_URL}?
				phone=${process.env.REACT_APP_SMS_ALERT_NUMBER}
				&apikey=${process.env.REACT_APP_SMS_ALERT_API_KEY}
				&text=*%E2%9C%89 New Contact Message*
				%0D%0A%0D%0ADate: ${date}
				%0D%0A%0D%0AName: ${this.name.current.value}
				%0D%0A%0D%0AContact: ${this.phone.current.value}
				%0D%0A%0D%0AEmail Address: ${this.email.current.value}
				%0D%0A%0D%0AMessage: ${this.message.current.value}
			`,
        )
        .then((response) => {
          console.log(response);
          const successMessage = document.getElementById('emailSuccessMessage');
          const showSuccessMessage = gsap.timeline();

          showSuccessMessage
            .from(successMessage, { duration: 0, scale: '0' })
            .to(successMessage, {
              duration: 0.5,
              scale: '1',
              ease: 'back.out(1.7)',
            })
            .to(
              successMessage,
              { duration: 0.5, scale: '0', ease: 'back.out(1.7)' },
              '<5',
            );

          this.setState({ showLoading: false });

          document.getElementById('contactUsForm').reset();
        })
        .catch((response) => {
          console.log(response);
          const successMessage = document.getElementById('emailSuccessMessage');
          const showSuccessMessage = gsap.timeline();

          showSuccessMessage
            .from(successMessage, { duration: 0, scale: '0' })
            .to(successMessage, {
              duration: 0.5,
              scale: '1',
              ease: 'back.out(1.7)',
            })
            .to(
              successMessage,
              { duration: 0.5, scale: '0', ease: 'back.out(1.7)' },
              '<5',
            );

          this.setState({ showLoading: false });

          document.getElementById('contactUsForm').reset();
        });
    } else {
      this.setState({ invalidInputs: true });
    }
  }

  render() {
    return (
      <div
        className={Styles.contactUsContainer}
        onClick={() => this.hideSuccessMessage()}>
        {this.state.showLoading ? <Loading /> : null}
        <Home color="rgb(247, 201, 68)" />
        <form id="contactUsForm" onSubmit={this.sendEmail.bind(this)}>
          <p className={Styles.helloText}>Hello, Let's Chirp!</p>
          <div className={Styles.inputsContainer}>
            <input
              name="from_name"
              ref={this.name}
              className={Styles.name}
              placeholder="Your beautiful name"
            />
            <input
              name="from_email"
              ref={this.email}
              className={Styles.email}
              placeholder="Email address"
            />
            <input
              name="contact"
              ref={this.phone}
              type="tel"
              className={Styles.name}
              placeholder="Contact Number"
            />
            <textarea
              name="message"
              ref={this.message}
              className={Styles.message}
              placeholder="Cheep cheep cheep..."
            />
          </div>
          <p
            className={
              this.state.invalidInputs ? Styles.errorShown : Styles.errorHidden
            }>
            Please enter valid information
          </p>
          <button
            id="contactUsSendBtn"
            className={Styles.sendBtn}
            type="submit"
            alt="send"></button>
        </form>
        <div className={Styles.chuchu}>
          <Chuchu width="300" height="300" />
        </div>
        <div
          id="emailSuccessMessage"
          onClick={() => this.hideSuccessMessage()}
          className={Styles.successMessageContainer}>
          <h1 className={Styles.successMessage}>
            Your email has been sent successfully!
          </h1>
          <div className={Styles.confetti}>
            <div id="contactUsLottieContainer" />
          </div>
        </div>
      </div>
    );
  }
}

export { ContactUs };
