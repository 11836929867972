import ClickSoundFile from '../assets/sounds/tap.mp3';
import SuccessSoundFile from '../assets/sounds/success.mp3';

export const ClickSound = () => {
  const clickAudio = new Audio(ClickSoundFile);

  clickAudio.play();
};

export const GameSuccessSound = () => {
  const clickAudio = new Audio(SuccessSoundFile);

  clickAudio.play();
};
