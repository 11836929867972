import React, { Component } from 'react';
import Styles from './introSection.module.scss';
import TrianglesLine from '../../../assets/triangles-line.svg';

class IntroSection extends Component {
  render() {
    return (
      <div className={Styles.mainContainer}>
        <img
          className={Styles.trianlgesLineTop}
          src={TrianglesLine}
          alt="border"
        />
        <img
          className={Styles.trianlgesLineBottom}
          src={TrianglesLine}
          alt="border"
        />
        <div className={Styles.textContainer}>
          <p className="text-muted m-0">They say,</p>
          <h2>"It takes a village to raise a child"</h2>
          <h4 className="mt-4">
            But somewhere along the way, we've lost the 'Village'
            <br />
            As a result, families are missing out on some of the most important
            experiences and much needed support systems. Building your own
            Village or Community can be challenging, but in the long run it can
            be totally worth it.
            <br />
            <br />
            The
            <span className={Styles.yellowText}>Yellow</span>
            Chuchu is playing an essential part in bringing the village back to
            enable a positive parenting community.
          </h4>
        </div>
      </div>
    );
  }
}

export { IntroSection };
