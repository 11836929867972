import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Styles from './aboutUs.module.scss';
import { HeroSection } from './heroSection';
import { ParentingSection } from './parentingSection';
import { IntroSection } from './introSection';
import { RegisterSection } from './registerSection';
import { GoToDashboardButton } from '../../components/svgAssets/goToDashboardButton';
import { ServicesSection } from './serviceSection';

class AboutUs extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <HeroSection />
        <ParentingSection />
        <IntroSection />
        <ServicesSection />
        <RegisterSection />
        <div className={Styles.dashboardLinkContainer}>
          <div className={Styles.dashboardLink}>
            <GoToDashboardButton />
          </div>
          <Link to="/" />
        </div>
      </div>
    );
  }
}

export { AboutUs };
