import Styles from './serviceSection.module.scss';
import Heart from '../../../assets/heart.png';
import Popup from '../../../assets/popup.png';
import Palette from '../../../assets/palette.png';

function ServicesSection() {
  return (
    <div className={Styles.servicesSectionContainer}>
      <h2>
        &#9702;<span className="mx-3">What we do</span>&#9702;
      </h2>
      <div className={`row d-flex w-100 ${Styles.contentContainer}`}>
        <div className={`col-12 col-lg-4 ${Styles.serviceCard}`}>
          <div>
            <img src={Heart} alt="heart" />
            <h4>Emotions Exploration</h4>
            <p>
              Fostering open communication about feelings and emotions within
              families, through engaging resources and activites.
            </p>
          </div>
        </div>
        <div className={`col-12 col-lg-4 ${Styles.serviceCard}`}>
          <div>
            <img src={Palette} alt="palette" />
            <h4>The Little Village</h4>
            <p>
              The Little Village holds Creative gatherings and workshops to
              develop a love for hands-on Arts & Crafts.
            </p>
          </div>
        </div>
        <div className={`col-12 col-lg-4 ${Styles.serviceCard}`}>
          <div>
            <img src={Popup} alt="popup" />
            <h4>Pop-Up Shops</h4>
            <p>
              Offering parent-child bonding activities through regular popup
              shops, featuring engaging activities and lots of love.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ServicesSection };
