import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FontAwesomeIcon from 'react-fontawesome';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Loading } from '../../../components/loading';
import { Alert } from '../../../components/alert';
import Styles from './product.module.scss';
import { addToCart } from '../../../store/actions';
import ShopData from '../../../data/shop.json';
import Heart from '../../../assets/mini-star.png';

function ProductScreen(props) {
  const productAssets = require.context(
    '../../../assets/shopAssets',
    true,
    /.*/,
  );
  const [activeImage, setActiveImage] = useState(0);
  const [productImages, setProductImages] = useState();
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [alert, setAlert] = useState({
    show: false,
    text: '',
  });
  const params = useParams();
  const navigate = useNavigate();
  const [cartProduct, setCartItemProduct] = useState(
    props.cart.products.find((cartItem) => cartItem.key === params.key),
  );
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    setCartItemProduct(
      props.cart.products.find((cartItem) => cartItem.key === params.key),
    );
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    const tmpProduct = ShopData.find((item) => item.key === params.key);

    if (!tmpProduct) {
      navigate('/page-not-found');
    } else {
      setProduct(tmpProduct);
    }
  }, [params, navigate]);

  useEffect(() => {
    if (product) {
      const images = [];

      for (let index = 0; index < product.imagesCount; index += 1) {
        images.push(
          <Carousel.Item key={index} className={Styles.carouselItem}>
            <img
              className={Styles.carouselImage}
              src={productAssets(`./${params.key}/${index}.jpg`)}
              alt={product.title}
            />
          </Carousel.Item>,
        );
      }

      setProductImages(images);
      setQuantity(1);

      document.title = `${product.title} | The Yellow Chuchu`;
    }
  }, [product, params.key]);

  function parseDescription(desc) {
    let description = [];

    description = desc.map((descItem) => {
      if (descItem.type === 'text') {
        return (
          <p style={{ minHeight: 20, letterSpacing: 0 }}>{descItem.text}</p>
        );
      }

      if (descItem.type === 'list') {
        return (
          <div
            style={{
              margin: '10px 0px 40px 5px',
              minHeight: 20,
              letterSpacing: 0,
              padding: 0,
            }}>
            {descItem.listItems.map((item) => (
              <div className={Styles.listItem} style={{ textAlign: 'left' }}>
                <div className="d-flex align-items-center">
                  <img
                    style={{
                      height: 18,
                      width: 18,
                      marginRight: 7,
                      marginBottom: 2,
                    }}
                    src={Heart}
                    alt="heart"
                  />
                  <strong> {item.title}:</strong>
                </div>
                <p
                  style={{
                    fontSize: 15,
                    marginBottom: 10,
                    marginLeft: 27,
                    width: 'calc(100% - 40px)',
                    textAlign: 'left',
                  }}>
                  {item.info}
                </p>
              </div>
            ))}
          </div>
        );
      }

      return <p></p>;
    });

    return description;
  }

  async function addProductToCart() {
    await setAlert({
      show: false,
      text: '',
    });

    if ((cartProduct?.quantity || 0) + quantity <= product.stock) {
      await props.addToCart({
        ...product,
        quantity: parseFloat(quantity),
      });

      setAlert({
        show: true,
        type: 'success',
        text: 'Item Added to Cart',
      });
    } else {
      setAlert({
        show: true,
        type: 'warning',
        text: 'You have added max stock items to your cart',
      });
    }
  }

  return (
    <div className={Styles.mainContainer}>
      {alert.show ? (
        <Alert text={alert.text} type={alert.type || 'success'} />
      ) : null}
      {product ? (
        <>
          <Link className={Styles.shopLink} to="/shop">
            <FontAwesomeIcon
              className={Styles.shopLinkIcon}
              name="chevron-left"
            />
            <span style={{ paddingLeft: 10 }}>SHOP</span>
          </Link>
          <Link className={Styles.cartLink} to="/cart">
            CART<span>({props.cart.products.length})</span>
          </Link>
          <div className={Styles.contentContainer}>
            <div className={Styles.carouselContainer}>
              <Carousel
                interval={null}
                onSlide={(x) => {
                  setActiveImage(x);
                }}
                nextIcon={
                  <span aria-hidden="true">
                    <FontAwesomeIcon
                      className={Styles.carouselIcon}
                      name="chevron-right"
                    />
                  </span>
                }
                prevIcon={
                  <span aria-hidden="true">
                    <FontAwesomeIcon
                      className={Styles.carouselIcon}
                      name="chevron-left"
                    />
                  </span>
                }>
                {productImages}
              </Carousel>
              <div className={Styles.thumbnailsContainer}>
                {[...Array(parseFloat(product.imagesCount))].map((i, index) => (
                  <img
                    className={
                      activeImage === index
                        ? Styles.activeThumb
                        : Styles.inactiveThumb
                    }
                    key={index}
                    src={productAssets(`./${product.key}/${index}.jpg`)}
                    alt={`${index}`}
                  />
                ))}
              </div>
            </div>
            <div className={Styles.infoContainer}>
              <div className="d-flex justify-content-md-start justify-content-center">
                {product.category.map((cat) => (
                  <span className={Styles.stickerLink}>
                    {(cat === 'box' ? 'Activity Boxes' : cat).toUpperCase()}
                  </span>
                ))}
              </div>
              <h3>{product.title}</h3>
              {!product.salePrice ? (
                <p className={Styles.price}>RS. {product.price}/-</p>
              ) : (
                <p className={Styles.salePrice}>
                  RS. {product.salePrice}
                  <span>RS. {product.price}/-</span>
                </p>
              )}
              <p className="mb-0">{parseDescription(product.description)}</p>
              {product.size && (
                <p className={`mt-2 ${Styles.smallText}`}>
                  SIZE: {product.size}
                </p>
              )}
              <p className={`mt-2 ${Styles.smallText}`}>
                AVAILABLE IN STOCK: {product.stock}
              </p>
              <p className={`mt-2 ${Styles.smallText}`}>
                (To place bulk orders, Please{' '}
                <Link
                  to={'/contact-us'}
                  className={`mr-2 ${Styles.salePrice} ${Styles.smallText}`}>
                  Get in Touch
                </Link>{' '}
                with the team)
              </p>
              <div className={Styles.quantityContainer}>
                <p className={`mr-2 ${Styles.smallText}`}>QUANTITY:</p>
                <button
                  className={Styles.quantityCounter}
                  onClick={() =>
                    setQuantity(quantity > 1 ? quantity - 1 : quantity)
                  }>
                  -
                </button>
                <p className={Styles.quantityText}>{quantity}</p>
                <button
                  disabled={
                    (cartProduct?.quantity || 0) + quantity >= product.stock
                  }
                  className={Styles.quantityCounter}
                  onClick={() =>
                    setQuantity(
                      (cartProduct?.quantity || 0) + quantity <= product.stock
                        ? quantity + 1
                        : quantity,
                    )
                  }>
                  +
                </button>
              </div>
              <button
                onClick={() => {
                  addProductToCart();
                }}
                className={Styles.addToCartBtn}>
                Add to Cart
              </button>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product) => dispatch(addToCart(product)),
});

const Product = connect(mapStateToProps, mapDispatchToProps)(ProductScreen);

export { Product };
