import Styles from './heroSection.module.scss';
import { Sprinkles } from '../../../components/svgAssets/sprinkles';
import { Chuchu } from '../../../components/svgAssets/chuchuCharacter';

function HeroSection() {
  return (
    <div className={Styles.heroSectionContainer}>
      <div className={Styles.landingSprinkles}>
        <Sprinkles />
      </div>
      <div className={`row w-100 ${Styles.contentContainer}`}>
        <div className={`col-6 text-left ${Styles.welcomeText}`}>
          <h1>
            THE <span>YELLOW</span> CHUCHU
          </h1>
          <h5 className="mt-3">
            Creating a village that raises
            <br />
            Happy, Positive and Mindful children.
          </h5>
        </div>
        <div className="col-6">
          {/* Add yellow blob behind chuchu */}
          <Chuchu width="16vw" />
        </div>
      </div>
    </div>
  );
}

export { HeroSection };
