import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const AppRoute = ({ Component, ...props }) => {
  useEffect(() => {
    document.title = `${props.title} | The Yellow Chuchu`;
  }, [props.title]);

  return (
    <>
      {!props.restricted ? (
        <Component {...props} />
      ) : (
        <Navigate to="/main" replace />
      )}
    </>
  );
};

export { AppRoute };
