import React from 'react';
import gsap, { Power1 } from 'gsap';
import { Link } from 'react-router-dom';
import Styles from './main.module.scss';
import { Chuchu } from '../../components/svgAssets/chuchuCharacter';
import { Sprinkles } from '../../components/svgAssets/sprinkles';
import ChirpSound from '../../assets/sounds/chuchu-chirp.mp3';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.chuchu = React.createRef();
    this.beakClickSound = new Audio(ChirpSound);
  }

  floatAnimation = () => {
    const chuchu = document.getElementById('chuchu');
    const floatChuchu = gsap.timeline({ repeat: -1 });

    floatChuchu
      .to(chuchu, { y: '-=30', ease: Power1.easeInOu, duration: 2 })
      .to(chuchu, { y: '+=30', ease: Power1.easeInOut, duration: 2 });
  };

  onBeakClick() {
    if (this.beakClickSound.paused) {
      this.beakClickSound.play();
    }
  }

  componentDidMount() {
    this.floatAnimation();

    window.scroll(0, 0);

    document.title = 'The Yellow Chuchu';
  }

  render() {
    return (
      <div className={Styles.mainScreen}>
        <div className={Styles.landingSprinkles}>
          <Sprinkles />
        </div>
        <Link to={'/contact-us'} className={Styles.sayHi}>
          Say hi
        </Link>
        {/* <Link to={'/register'} className={Styles.registerLink}>Register Now</Link> */}
        <div className={`text-center row m-0 ${Styles.mainContainer}`}>
          <div className={`text-center col-12 col-md-4 ${Styles.intro}`}>
            <h3 className={Styles.introText}>
              Hi, I'm the <span className={Styles.yellow}>Yellow</span> chuchu.
            </h3>
            <h3 className={Styles.introText}>Let's get started!</h3>
          </div>
          <div className={`col-12 col-md-4 ${Styles.chuchu}`}>
            <Chuchu width="20vw" onBeakClick={this.onBeakClick.bind(this)} />
          </div>
          <div className={`col-12 col-md-4 ${Styles.links}`}>
            <ul className={Styles.linksList}>
              <li className={Styles.listItem}>
                <Link to={'/about-me'} className={Styles.listLinkItem}>
                  About Me
                </Link>
              </li>
              <li className={Styles.listItem}>
                <Link to={'/games'} className={Styles.listLinkItem}>
                  Games
                </Link>
              </li>
              {/* <li className={Styles.listItem}>
                <Link to={'/chuchu-board'} className={Styles.listLinkItem}>
                  Chuchu Board
                </Link>
              </li> */}
              <li className={Styles.listItem}>
                <Link to={'/events'} className={Styles.listLinkItem}>
                  Events
                </Link>
              </li>
              <li className={Styles.listItem}>
                <Link to={'/printables'} className={Styles.listLinkItem}>
                  Printables
                </Link>
              </li>
              <li className={Styles.listItem}>
                <Link to={'/shop'} className={Styles.listLinkItem}>
                  Shop
                </Link>
              </li>
              <p className={Styles.mutedText}>More on the way!</p>
            </ul>
          </div>
        </div>
        <p className={Styles.beakClickText}>
          (Psst, Turn the volume up and click on my beak!)
        </p>
      </div>
    );
  }
}

export { LandingPage };
