import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from './game.module.scss';
import { Back } from '../../../../components/svgAssets/back';
import GamesData from '../../../../data/games/findMyTwin.json';
import { GameSuccessModal } from '../../../../components/gameSuccessModal';
import { GameSuccessSound } from '../../../../utils';

function FindMyTwinGame() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const gameData = GamesData.find((game) => game.key === params.name);

    if (!gameData) {
      navigate('/page-not-found');
    }
  }, [params.name, navigate]);

  return GamesData.find((game) => game.key === params.name) ? (
    <FindMyTwinGameOriginal navigate={navigate} params={params} />
  ) : null;
}

class FindMyTwinGameOriginal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameData: '',
      gameCards: [],
      selectedCardIndex: '',
      firstCardIndex: '',
      firstCard: '',
      twinsFound: 0,
      totalTwins: 0,
      success: false,
    };
  }

  shuffle(array) {
    const arrayObj = array;
    let currentIndex = arrayObj.length;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      [arrayObj[currentIndex], arrayObj[randomIndex]] = [
        arrayObj[randomIndex],
        arrayObj[currentIndex],
      ];
    }

    return arrayObj;
  }

  resetCards(firstElement, secondElement) {
    setTimeout(() => {
      firstElement.classList.remove(Styles.flippedCard);
      secondElement.classList.remove(Styles.flippedCard);
    }, 500);
  }

  async flipCard(index, e) {
    if (e.target !== this.state.firstCard) {
      e.target.classList.add(Styles.flippedCard);

      if (!this.state.firstCard) {
        this.setState({
          firstCard: e.target,
          firstCardIndex: index,
        });
      } else if (this.state.firstCardIndex === index) {
        if (this.state.twinsFound + 1 === this.state.totalTwins) {
          GameSuccessSound();

          this.setState({ success: true });
        } else {
          const updatedElement = this.state.firstCard;

          updatedElement.style.pointerEvents = 'none';
          e.target.style.pointerEvents = 'none';
        }

        await this.setState({
          firstCard: '',
          firstCardIndex: '',
          twinsFound: this.state.twinsFound + 1,
        });
      } else {
        this.resetCards(this.state.firstCard, e.target);

        this.setState({
          firstCard: '',
          firstCardIndex: '',
        });
      }
    }
  }

  async componentDidMount() {
    const gameData = GamesData.find(
      (game) => game.key === this.props.params.name,
    );

    document.title = `Find My Twin ${gameData.title} | The Yellow Chuchu`;

    const gamesAssets = require.context(
      '../../../../assets/gamesAssets/findMyTwin',
      true,
      /.*/,
    );
    const gameCards = [];

    for (let index = 0; index < gameData.imagesCount; index += 1) {
      gameCards.push(
        <div key={`${index}-1`} className={Styles.gameCardContainer}>
          <div
            className={Styles.cardContent}
            onClick={async (e) => {
              await this.flipCard(index, e);
            }}>
            <div className={Styles.cardFront}></div>
            <div className={Styles.cardBack}>
              <img
                src={gamesAssets(`./${gameData.key}/${index}.svg`)}
                alt={`${gameData.key}-${index}-${index}`}
              />
            </div>
          </div>
        </div>,
      );

      gameCards.push(
        <div key={`${index}-2`} className={Styles.gameCardContainer}>
          <div
            className={Styles.cardContent}
            onClick={(e) => {
              this.flipCard(index, e);
            }}>
            <div className={Styles.cardFront}></div>
            <div className={Styles.cardBack}>
              <img
                src={gamesAssets(`./${gameData.key}/${index}.svg`)}
                alt={`${gameData.key}-${index}-${index}`}
              />
            </div>
          </div>
        </div>,
      );
    }

    this.shuffle(gameCards);

    await this.setState({
      gameData,
      gameCards,
      totalTwins: gameData.imagesCount,
    });
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <h1 className={Styles.heading}>
          Find My Twin {this.state.gameData.title}
        </h1>
        <Back link="game/find-my-twin" />
        <div className={Styles.scoreText}>
          <div className="d-flex justify-content-end">
            <p>Twins Found: </p>
            <p>{this.state.twinsFound}</p>
          </div>
          <div className="d-flex justify-content-end">
            <p>Twins Left: </p>
            <p>{this.state.totalTwins - this.state.twinsFound}</p>
          </div>
        </div>
        <div className={Styles.gameCardsContainer}>{this.state.gameCards}</div>
        {this.state.success ? (
          <GameSuccessModal message={this.state.gameData.successMessage} />
        ) : null}
      </div>
    );
  }
}

export { FindMyTwinGame };
