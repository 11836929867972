import React, { Component } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Styles from './parentingSection.module.scss';

class ParentingSection extends Component {
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    const timeline = gsap.timeline();

    timeline.to('#parentingSection', {
      scrollLeft:
        document.getElementById('parentingIsBeautiful').offsetWidth -
        window.innerWidth +
        200,
      scrollTrigger: {
        trigger: '#parentingIsBeautiful',
        start: 'top bottom-=400',
        end: 'top top+=200',
        scrub: 5,
      },
    });
  }

  render() {
    return (
      <div id="parentingSection" className={Styles.mainContainer}>
        <h1 id="parentingIsBeautiful">
          &#8226; Parenting is Beautiful &#8226;
        </h1>
      </div>
    );
  }
}

export { ParentingSection };
