import React, { Component } from 'react';
import axios from 'axios';
import Styles from './registrationForm.module.scss';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.firstName = React.createRef('');
    this.lastName = React.createRef('');
    this.email = React.createRef('');
    this.contact = React.createRef('');
    this.message = React.createRef('');
    this.state = { buttonText: 'Sign Up' };
  }

  registerUser(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ buttonText: 'Loading...' });

    const date = new Date().toLocaleString('en-PK');

    axios
      .post(
        `${process.env.REACT_APP_SMS_ALERT_URL}?
				phone=${process.env.REACT_APP_SMS_ALERT_NUMBER}
				&apikey=${process.env.REACT_APP_SMS_ALERT_API_KEY}
				&text=*%E2%9C%8F  New Signup for Notifications*
				%0D%0A%0D%0ADate: ${date}
				%0D%0A%0D%0AFirst Name: ${this.firstName.current.value}
				%0D%0A%0DLast Name: ${this.lastName.current.value}
				%0D%0A%0D%0AEmail Address: ${this.email.current.value}
				%0D%0A%0D%0AContact: ${this.contact.current.value}
				%0D%0A%0D%0AMessage: ${this.message.current.value}
			`,
      )
      .then(() => {
        this.firstName.current.value = '';
        this.lastName.current.value = '';
        this.email.current.value = '';
        this.contact.current.value = '';
        this.message.current.value = '';

        this.setState({ buttonText: 'Registered Successfully' });

        setTimeout(() => {
          this.setState({ buttonText: 'Sign Up' });
        }, 3000);
      })
      .catch(() => {
        this.firstName.current.value = '';
        this.lastName.current.value = '';
        this.email.current.value = '';
        this.contact.current.value = '';
        this.message.current.value = '';

        this.setState({ buttonText: 'Registered Successfully' });

        setTimeout(() => {
          this.setState({ buttonText: 'Sign Up' });
        }, 5000);
      });
  }

  render() {
    return (
      <>
        <form
          className={Styles.formContainer}
          onSubmit={(e) => this.registerUser(e)}>
          <input
            ref={this.firstName}
            required
            name="first_name"
            type="text"
            className={Styles.input}
            placeholder="First Name"
          />
          <input
            ref={this.lastName}
            required
            name="last_name"
            type="text"
            className={Styles.input}
            placeholder="Last Name"
          />
          <input
            ref={this.email}
            required
            name="email"
            type="email"
            className={Styles.input}
            placeholder="Email Address"
          />
          <input
            ref={this.contact}
            required
            name="contact"
            type="tel"
            className={Styles.input}
            placeholder="Contact Number"
          />
          <textarea
            ref={this.message}
            name="message"
            type="text"
            rows={5}
            className={Styles.input}
            placeholder="Additional Notes"
          />
          <button
            disabled={this.state.buttonText !== 'Sign Up'}
            className={Styles.registerButton}
            type="submit">
            {this.state.buttonText}
          </button>
          <p className={Styles.successText}>
            {this.state.buttonText === 'Registered Successfully' &&
              "Awesome! We'll get in touch with you soon."}
          </p>
        </form>
      </>
    );
  }
}

export { RegistrationForm };
