import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Styles from './shop.module.scss';
import { Home } from '../../components/svgAssets/home';
import ShopData from '../../data/shop.json';
import CategoriesData from '../../data/categories.json';

function ShopScreen(props) {
  const params = useParams();

  return <ShopComponent cart={props.cart} params={params} />;
}

class ShopComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProducts: 'all',
      allProducts: ShopData,
      products: ShopData,
    };
  }

  updateProducts(tag) {
    this.setState({ showProducts: tag });

    if (tag === 'all') {
      this.setState({ products: this.state.allProducts });
    } else {
      this.setState({
        // eslint-disable-next-line
        products: this.state.allProducts.filter(
          (product) => product.category.includes(tag),
          // eslint-disable-next-line
        ),
      });
    }
  }

  addProductToCart(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const productAssets = require.context(
      '../../assets/shopAssets',
      true,
      /.*/,
    );

    return (
      <div className={Styles.shopContainer}>
        <Home color="#fabe37" />
        <Link className={Styles.cartLink} to="/cart">
          CART<span>({this.props.cart.products.length})</span>
        </Link>
        <h3 className={Styles.mainHeading}>CHUCHU SHOP</h3>
        <div className={`d-none d-lg-flex ${Styles.desktopFiltersContainer}`}>
          <button
            onClick={() => {
              this.updateProducts('all');
            }}
            className={
              this.state.showProducts === 'all' ? Styles.activeButton : null
            }>
            All
          </button>
          {CategoriesData.map((cat) => (
            <button
              key={cat.key}
              onClick={() => {
                this.updateProducts(cat.key);
              }}
              className={
                this.state.showProducts === cat.key ? Styles.activeButton : null
              }>
              {cat.name}
            </button>
          ))}
        </div>
        <div className={`d-flex d-lg-none ${Styles.mobileFiltersContainer}`}>
          <p className="mb-1">Select Category</p>
          <select
            onChange={(e) => {
              this.updateProducts(e.target.value);
            }}>
            <option value="all">All</option>
            {CategoriesData.map((cat) => (
              <option key={cat.key} value={cat.key}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        {this.state.products.length ? (
          <>
            <div className={`row mt-1 ${Styles.productsContainer}`}>
              {this.state.products.length ? (
                this.state.products.map((product) => (
                  <Link
                    key={product.key}
                    to={`/product/${product.key}`}
                    className={Styles.product}>
                    {product.salePrice && (
                      <div className={Styles.saleTag}>SALE</div>
                    )}
                    <div className={Styles.productIconContainer}>
                      <img
                        className={Styles.productIcon}
                        src={productAssets(`./${product.key}/0.jpg`)}
                        alt="stickers"
                      />
                    </div>
                    <div className={Styles.productTextContainer}>
                      <p>{product.title}</p>
                      {product.salePrice ? (
                        <p className={Styles.salePrice}>
                          Rs.{product.salePrice}
                          <span>Rs.{product.price}</span>
                        </p>
                      ) : (
                        <p className={Styles.price}>Rs.{product.price}</p>
                      )}
                    </div>
                  </Link>
                ))
              ) : (
                <p className="w-100 mt-5 pt-5 text-center text-muted">
                  No products found!
                </p>
              )}
            </div>
          </>
        ) : (
          <p className="text-muted mt-5 pt-5">No products found</p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const Shop = connect(mapStateToProps, null)(ShopScreen);

export { Shop };
