import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './svgAssets.module.scss';
import { ClickSound } from '../../utils';

function Home(props) {
  return (
    <Link
      to={'/'}
      onClick={() => {
        ClickSound();
      }}>
      <svg
        className={`${Styles.home} ${props.styles}`}
        xmlns="http://www.w3.org/2000/svg"
        width="812.09"
        height="690.277"
        viewBox="0 0 812.09 690.277">
        <path
          d="M327.836,694.777V451.15H490.254V694.777H693.277V369.941H815.09L409.045,4.5,3,369.941H124.814V694.777Z"
          transform="translate(-3 -4.5)"
          fill={props.color ? props.color : '#fff'}
        />
      </svg>
    </Link>
  );
}

export { Home };
