/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Home } from '../../components/svgAssets/home';
import Triangles from '../../assets/triangles.svg';
import Styles from './printables.module.scss';
import { PrintablesData } from '../../data/printables';
import axios from 'axios';
import html2pdf from 'html2pdf.js';

export default function Printables() {
  const componentRef = useRef();
  const [showPrintables, setShowPrintables] = useState('all');
  const [printables, setPrintables] = useState(PrintablesData);
  const [showLoading, setShowLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  function updatePrintables(tag) {
    setShowPrintables(tag);

    if (tag === 'all') {
      setPrintables(PrintablesData);
    } else {
      setPrintables(
        PrintablesData.filter((p) =>
          tag === 'coloring' ? p.tag === 'coloring' : true,
        ),
      );
    }
  }

  function showPrintableDetails(event) {
    setShowDetails(event);

    gsap.to('#printableDetailsContainer', 0.3, { visibility: 'visible' });
    gsap.to('#printableDetailsContent', 0.3, {
      scale: 1,
      ease: 'back',
    });
  }

  function hideEventDetails() {
    setShowDetails('');

    const timeline = gsap.timeline();

    timeline
      .to('#printableDetailsContent', 0.3, {
        scale: 0,
        ease: 'back',
      })
      .to('#printableDetailsContainer', 0, { visibility: 'hidden' });
  }

  async function downloadPDF(e) {
    e.stopPropagation();
    e.preventDefault();

    await setShowLoading(true);

    var element = componentRef.current;
    var opt = {
      margin: 0,
      filename: `${showDetails.title}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
      },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };
    html2pdf().set(opt).from(element).save();

    const date = new Date().toLocaleString('en-PK');

    axios
      .post(
        `${process.env.REACT_APP_SMS_ALERT_URL}?
            phone=${process.env.REACT_APP_SMS_ALERT_NUMBER}
            &apikey=${process.env.REACT_APP_SMS_ALERT_API_KEY}
            &text=*🌟📥 Printable Downloaded*
            %0D%0A%0D%0A Date: ${date}
            %0D%0A%0D%0AName: ${e.target[0].value} ${e.target[1].value}
            %0D%0A%0D%0AEmail Address: ${e.target[2].value}
            %0D%0A%0D%0APrintable: ${showDetails.id}
          `,
      )
      .then(() => {
        setShowLoading(false);

        document.getElementById('DownloadForm').reset();
        hideEventDetails();
      })
      .catch(() => {
        setShowLoading(false);
        document.getElementById('DownloadForm').reset();
        hideEventDetails();
      });
  }

  useEffect(() => {
    if (showDetails) {
      window.scroll(0, 0);
      document.documentElement.style.overflow = 'hidden';
    } else {
      setShowLoading(false);
      document.getElementById('DownloadForm').reset();
      document.documentElement.style.overflow = 'auto';
    }
  }, [showDetails]);

  return (
    <div className={Styles.mainContainer}>
      <h1 className={Styles.heading}>Printables</h1>
      <Home color="#fabe37" />
      <img className={Styles.topTriangles} src={Triangles} alt="triangles" />
      <img className={Styles.bottomTriangles} src={Triangles} alt="triangles" />

      <div className={Styles.buttonsContainer}>
        <button
          onClick={() => {
            updatePrintables('all');
          }}
          className={showPrintables === 'all' ? Styles.activeButton : null}>
          All
        </button>
        <button
          onClick={() => {
            updatePrintables('coloring');
          }}
          className={
            showPrintables === 'coloring' ? Styles.activeButton : null
          }>
          Coloring pages
        </button>
      </div>
      <div className={Styles.eventsContainer}>
        {printables?.length ? (
          printables.map((event) => (
            <div
              key={event.index}
              className={Styles.card}
              onClick={() => {
                showPrintableDetails(event);
              }}>
              <div>
                <img
                  className={Styles.eventIcon}
                  src={event.image}
                  alt="printable"
                />
              </div>
              <div>
                <h5 className="mb-1">{event.title}</h5>
                <div className="d-flex align-items-center">
                  <p className="m-0">{event.description1}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p
            style={{
              margin: 50,
            }}>
            Oops! No printables found.
          </p>
        )}
      </div>
      <div id="printableDetailsContainer" className={Styles.detailsModal}>
        <div id="printableDetailsContent" className={Styles.detailsContent}>
          <img
            className={Styles.starIcon}
            src={showDetails.image}
            height="100"
            alt="event"
          />
          <h4 className="mt-3">{showDetails.title}</h4>
          <p className="m-0 text-muted">{showDetails.venue}</p>
          <p className="mt-0">{showDetails.description1}</p>
          <p className="m-0">{showDetails.description2}</p>
          <p className="m-0">{showDetails.description3}</p>
          <p style={{ opacity: 0.5 }}>Please fill the form below to download</p>
          <form
            id="DownloadForm"
            onSubmit={(data) => {
              downloadPDF(data);
            }}>
            <div className={Styles.inputContainer}>
              <input placeholder="First Name" required />
            </div>
            <div className={Styles.inputContainer}>
              <input placeholder="Last Name" required />
            </div>
            <div className={Styles.inputContainer}>
              <input placeholder="Email Address" type="email" required />
            </div>
            <button
              disabled={showLoading}
              type="submit"
              className={Styles.downloadButton}>
              {showLoading ? 'Downloading...' : 'Download'}
            </button>
          </form>
          <button
            className={Styles.closeButton}
            onClick={() => {
              hideEventDetails();
            }}>
            Close
          </button>
        </div>
      </div>
      {showDetails ? (
        <>
          <div style={{ marginTop: 1000 }}></div>
          <div ref={componentRef} style={{ width: 1100 }}>
            <div
              style={{
                height: 820,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <p
                className="w-100 mt-4 text-center"
                style={{ fontSize: 40, fontWeight: 600 }}>
                THE <span style={{ color: '#f8ab03' }}>YELLOW</span> CHUCHU
              </p>
              <p
                className="w-100 mt-4 text-center"
                style={{ fontSize: 35, fontWeight: 500 }}>
                {showDetails.title}
              </p>
              <p
                style={{
                  textAlign: 'center',
                  width: 500,
                  marginTop: 30,
                  color: '#444',
                }}>
                These drawings have been lovingly made as an ode to my mother’s
                passion for parenting. Each stroke is infused with a lot of
                consideration and love for parenting that she has poured into
                me.
                <br />
                <br />
                As you enjoy these drawings, I encourage you to send warm wishes
                and gratitude to my mother for her endless love and inspiration.
                <br />
                <br />
                Thank you and Happy Parenting!
              </p>
            </div>
            {showDetails?.imagesList
              ? showDetails?.imagesList.map((image) => (
                  <div
                    style={{
                      width: 1100,
                      height: 815,
                      padding: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <img
                      style={{
                        height: '88%',
                        objectFit: 'contain',
                      }}
                      src={image}
                      alt="ABC Kids"
                    />
                  </div>
                ))
              : null}
          </div>
        </>
      ) : null}
    </div>
  );
}

export { Printables };
