import React from 'react';
import ReactDOM from 'react-dom/client';
import { inject } from '@vercel/analytics';
import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import { App } from './components/app';

const root = ReactDOM.createRoot(document.getElementById('root'));

inject();

root.render(
	<Provider store={store}>
		<Router>
			<PersistGate persistor={persistStore(store)}>
				<App />
			</PersistGate>
		</Router>
	</Provider>
);
