import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AppRoute } from './appRoute';
import { LandingPage } from '../views/main';
import { ContactUs } from '../views/contactUs';
import { PageNotFound } from '../views/pageNotFound';
import { AboutUs } from '../views/aboutUs';
import { Games } from '../views/games';
import { ChuchuBoard } from '../views/chuchuBoard';
import { FindMyTwinMain } from '../views/games/findMyTwin';
import { FindMyTwinGame } from '../views/games/findMyTwin/game';
import { WhatsDifferentMain } from '../views/games/whatsDifferent';
import { WhatsDifferentGame } from '../views/games/whatsDifferent/game';
import { Footer } from './footer';
// import { Registration } from '../views/registration';
import { Events } from '../views/events';
import { Printables } from '../views/printables';

// Shop
import { Shop } from '../views/shop';
import { Product } from '../views/shop/product';
import { Cart } from '../views/shop/cart';
import { Checkout } from '../views/shop/checkout';
import { OrderSuccess } from '../views/shop/orderSuccess';

function App() {
  const location = useLocation();
  const [showView, setShowView] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent || navigator.vendor;
    const isInstagram = ua.indexOf('Instagram') > -1;

    if (isInstagram) {
      if (/iPad|iPhone|iPod/.test(ua)) {
        window.location.href = `googlechrome://theyellochuchu${location.pathname}.com`;
        return;
      }
      window.location.href = `intent:https://theyellowchuchu.com${location.pathname}#Intent;end`;
    } else {
      setShowView(true);
    }
  }, []);

  return showView ? (
    <>
      <Routes>
        <Route
          path="/"
          element={<AppRoute title="Dashboard" Component={LandingPage} />}
        />
        <Route
          path="/contact-us"
          element={<AppRoute title="Contact Us" Component={ContactUs} />}
        />
        <Route
          path="/about-me"
          element={<AppRoute title="About Me" Component={AboutUs} />}
        />
        {/* <Route path="/register" element={<AppRoute
					 title="Register" Component={Registration} />} /> */}
        <Route
          path="/chuchu-board"
          element={<AppRoute title="Chuchu Board" Component={ChuchuBoard} />}
        />

        {/* Games */}
        <Route
          path="/games"
          element={<AppRoute title="Games" Component={Games} />}
        />
        {/* Find My Twin */}
        <Route
          path="/game/find-my-twin"
          element={<AppRoute title="Find My Twin" Component={FindMyTwinMain} />}
        />
        <Route
          path="/game/find-my-twin/:name"
          element={<AppRoute title="Find My Twin" Component={FindMyTwinGame} />}
        />
        {/* What's Different */}
        <Route
          path="/game/what's-different"
          element={
            <AppRoute
              title="What's Different?"
              Component={WhatsDifferentMain}
            />
          }
        />
        <Route
          path="/game/what's-different/:name"
          element={
            <AppRoute
              title="What's Different?"
              Component={WhatsDifferentGame}
            />
          }
        />

        {/* Shop */}
        <Route
          path="/shop"
          element={<AppRoute title="Chuchu Shop" Component={Shop} />}
        />
        <Route
          path="/shop/:category"
          element={<AppRoute title="Chuchu Shop" Component={Shop} />}
        />
        <Route
          path="/product/:key"
          element={<AppRoute title="Product" Component={Product} />}
        />
        <Route
          path="/cart"
          element={<AppRoute title="Cart" Component={Cart} />}
        />
        <Route
          path="/checkout"
          element={<AppRoute title="Checkout" Component={Checkout} />}
        />
        <Route
          path="/order-success"
          element={<AppRoute title="Order Placed" Component={OrderSuccess} />}
        />

        <Route path="*" element={<PageNotFound />} />

        <Route
          path="/events"
          element={<AppRoute title="Events" Component={Events} />}
        />

        {/* Printables */}

        <Route
          path="/printables"
          element={<AppRoute title="Printables" Component={Printables} />}
        />
      </Routes>
      <Footer />
    </>
  ) : (
    ''
  );
}

export { App };
