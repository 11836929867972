import React, { useEffect } from 'react';
import FontAwesomeIcon from 'react-fontawesome';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Lottie from 'lottie-web';
import { connect } from 'react-redux';
import { clearCart } from '../../../store/actions';
import CheckLottie from '../../../assets/lotties/check.json';
import Styles from './orderSuccess.module.scss';
import ChuchuRight from '../../../assets/chuchu-right.svg';

function OrderSuccessScreen(props) {
  const { state } = useLocation();

  useEffect(() => {
    props.clearCart();

    if (document.getElementById('checkLottieContainer').children.length === 0) {
      Lottie.loadAnimation({
        container: document.getElementById('checkLottieContainer'),
        animationData: CheckLottie,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        name: 'benefit',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
      });
    }

    // eslint-disable-next-line
  }, []);

  return state ? (
    <div className={Styles.mainContainer}>
      <Link to="/shop" className={Styles.cartLink}>
        <FontAwesomeIcon name="chevron-left" style={{ marginRight: '10px' }} />{' '}
        SHOP
      </Link>
      <div className={Styles.orderInfoContainer}>
        <div className={Styles.chuchuContainer}>
          <img
            className={Styles.chuchu}
            src={ChuchuRight}
            alt="The Yellow Chuchu"
          />
          <div className={Styles.lottieContainer} id="checkLottieContainer" />
        </div>
        <h3 className="pb-4">Order placed successfully!</h3>
        <p className={`${Styles.text} mb-1`}>
          ORDER ID: <span>{state.orderID}</span>
        </p>
        {state.paymentMethod === 'COD' ? (
          <p className={`${Styles.text} mb-3`}>
            PAYMENT METHOD: Cash on Delivery
          </p>
        ) : null}
        {/* eslint-disable */}
        {state.paymentMethod === 'JAZZCASH' ||
        state.paymentMethod === 'BANK TRANSFER' ? (
          <p className={Styles.paymentMethodInfo}>
            {
              'Kindly send your payment screenshot to the following Whatsapp number to confirm your order: 0325-1779407'
            }
          </p>
        ) : null}
        {/* eslint-disable */}
        {state.paymentMethod === 'JAZZCASH' ? (
          <div className="mt-2 mb-3 text-left">
            <p className="m-0">Jazz Cash Number:</p>
            <p className={Styles.accountInfo}>0325-1779407</p>
          </div>
        ) : null}
        {state.paymentMethod === 'BANK TRANSFER' ? (
          <div className="mt-2 mb-3 text-center">
            <p className="m-0">Bank Account Details:</p>
            <p className={Styles.accountInfo}>Askari Bank</p>
            <p className={Styles.accountInfo}>0705-0200-0123-00</p>
          </div>
        ) : null}
        <Link to="/">GO TO DASHBOARD</Link>
      </div>
    </div>
  ) : (
    <Navigate to="/shop" replace />
  );
}

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart()),
});

const OrderSuccess = connect(null, mapDispatchToProps)(OrderSuccessScreen);

export { OrderSuccess };
