import React from 'react';
import gsap from 'gsap';
import Styles from './svgAssets.module.scss';

function Sprinkles() {
  const interactProps = {
    onMouseEnter: (e) => {
      gsap.to(e.target, { rotation: '+=180' });
    },
    onMouseOut: (e) => {
      gsap.to(e.target, { rotation: '+=180' });
    },
  };

  return (
    <svg
      className={Styles.sprinkles}
      xmlns="http://www.w3.org/2000/svg"
      height="150px"
      viewBox="0 100 1840 100">
      <g>
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(-0.978, 0.208, -0.208, -0.978, 505.551, 188.786)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="translate(1641.583 47) rotate(49)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="translate(1774.583 149) rotate(49)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.087, -0.996, 0.996, 0.087, 633.218, 69.96)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.485, -0.875, 0.875, 0.485, 1428.755, 143.514)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.485, -0.875, 0.875, 0.485, 208.755, 155.514)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.951, -0.309, 0.309, 0.951, 950.788, 46.093)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.951, -0.309, 0.309, 0.951, 362.788, 34.093)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.951, -0.309, 0.309, 0.951, 1125.788, 139.093)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.951, -0.309, 0.309, 0.951, 72.788, 80.093)"
          fill="#f6813a"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="translate(829.01 142.973) rotate(43)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="translate(729.01 232.973) rotate(140)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="matrix(0.485, -0.875, 0.875, 0.485, 1276.755, 56.514)"
          fill="#f6c644"
        />
        <rect
          {...interactProps}
          width="5"
          height="30"
          rx="2.5"
          transform="translate(64.583 234) rotate(49)"
          fill="#f6c644"
        />
      </g>
    </svg>
  );
}

export { Sprinkles };
