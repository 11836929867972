import React, { useEffect } from 'react';
import gsap from 'gsap';
import Lottie from 'lottie-web';
import { Link } from 'react-router-dom';
import confettiLottie from '../../assets/lotties/confetti-2.json';
import Styles from './gameSuccessModal.module.scss';

function GameSuccessModal(props) {
  useEffect(() => {
    gsap.to('#gameCompleteContainer', 0.3, { visibility: 'visible' });
    gsap.to('#successContent', 0.3, {
      scale: 1,
      ease: 'back',
    });

    setTimeout(() => {
      if (
        document.getElementById('GameCompleteLottie') &&
        document.getElementById('GameCompleteLottie').children.length === 0
      ) {
        Lottie.loadAnimation({
          container: document.getElementById('GameCompleteLottie'),
          animationData: confettiLottie,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          name: 'benefit',
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
          },
        });
      }
    }, 100);
  }, [props.message]);

  return (
    <div id="gameCompleteContainer" className={Styles.successContainer}>
      <div id="successContent" className={Styles.successContent}>
        <div className={Styles.lottieContainer}>
          <div id="GameCompleteLottie" />
        </div>
        <h1>Awesome Job!</h1>
        <h4>{props.message}</h4>
        <button
          onClick={() => {
            window.location.reload();
          }}>
          Play Again
        </button>
        <Link to="/games">Go To Games</Link>
      </div>
    </div>
  );
}

export { GameSuccessModal };
