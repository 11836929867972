import React, { Component } from 'react';
import Styles from './chuchuBoard.module.scss';
import { Banner } from '../../components/svgAssets/banner';
import { Home } from '../../components/svgAssets/home';
import ComingSoon from '../../assets/comingSoon.png';

class ChuchuBoard extends Component {
  render() {
    return (
      <div className={`text-center ${Styles.mainContainer}`}>
        <Home color="#f1617d" />
        <div className={Styles.banner}>
          <Banner color="#f1617d" />
        </div>
        <div>
          <div className={Styles.topDrawing}>
            <img src={ComingSoon} alt="coming soon" />
          </div>
        </div>
      </div>
    );
  }
}

export { ChuchuBoard };
