import React, { Component } from 'react';
import { RegistrationForm } from '../../../components/registrationForm';
import Styles from './registerSection.module.scss';

class RegisterSection extends Component {
  render() {
    return (
      <div className={Styles.mainContainer}>
        <h2>
          &#9702;<span className="mx-3">Sounds Eggs-citing?</span>&#9702;
        </h2>
        <p>Sign up for latest updates</p>
        <RegistrationForm />
      </div>
    );
  }
}

export { RegisterSection };
