import ABC from '../assets/printables/abc-colored.png';
import ABCUncolored from '../assets/printables/abc-uncolored.png';

import aColored from '../assets/printables/abc-colored/a.png';
import bColored from '../assets/printables/abc-colored/b.png';
import cColored from '../assets/printables/abc-colored/c.png';
import dColored from '../assets/printables/abc-colored/d.png';
import eColored from '../assets/printables/abc-colored/e.png';
import fColored from '../assets/printables/abc-colored/f.png';
import gColored from '../assets/printables/abc-colored/g.png';
import hColored from '../assets/printables/abc-colored/h.png';
import iColored from '../assets/printables/abc-colored/i.png';
import jColored from '../assets/printables/abc-colored/j.png';
import kColored from '../assets/printables/abc-colored/k.png';
import lColored from '../assets/printables/abc-colored/l.png';
import mColored from '../assets/printables/abc-colored/m.png';
import nColored from '../assets/printables/abc-colored/n.png';
import oColored from '../assets/printables/abc-colored/o.png';
import pColored from '../assets/printables/abc-colored/p.png';
import qColored from '../assets/printables/abc-colored/q.png';
import rColored from '../assets/printables/abc-colored/r.png';
import sColored from '../assets/printables/abc-colored/s.png';
import tColored from '../assets/printables/abc-colored/t.png';
import uColored from '../assets/printables/abc-colored/u.png';
import vColored from '../assets/printables/abc-colored/v.png';
import wColored from '../assets/printables/abc-colored/w.png';
import xColored from '../assets/printables/abc-colored/x.png';
import yColored from '../assets/printables/abc-colored/y.png';
import zColored from '../assets/printables/abc-colored/z.png';

import aUncolored from '../assets/printables/abc-uncolored/a.png';
import bUncolored from '../assets/printables/abc-uncolored/b.png';
import cUncolored from '../assets/printables/abc-uncolored/c.png';
import dUncolored from '../assets/printables/abc-uncolored/d.png';
import eUncolored from '../assets/printables/abc-uncolored/e.png';
import fUncolored from '../assets/printables/abc-uncolored/f.png';
import gUncolored from '../assets/printables/abc-uncolored/g.png';
import hUncolored from '../assets/printables/abc-uncolored/h.png';
import iUncolored from '../assets/printables/abc-uncolored/i.png';
import jUncolored from '../assets/printables/abc-uncolored/j.png';
import kUncolored from '../assets/printables/abc-uncolored/k.png';
import lUncolored from '../assets/printables/abc-uncolored/l.png';
import mUncolored from '../assets/printables/abc-uncolored/m.png';
import nUncolored from '../assets/printables/abc-uncolored/n.png';
import oUncolored from '../assets/printables/abc-uncolored/o.png';
import pUncolored from '../assets/printables/abc-uncolored/p.png';
import qUncolored from '../assets/printables/abc-uncolored/q.png';
import rUncolored from '../assets/printables/abc-uncolored/r.png';
import sUncolored from '../assets/printables/abc-uncolored/s.png';
import tUncolored from '../assets/printables/abc-uncolored/t.png';
import uUncolored from '../assets/printables/abc-uncolored/u.png';
import vUncolored from '../assets/printables/abc-uncolored/v.png';
import wUncolored from '../assets/printables/abc-uncolored/w.png';
import xUncolored from '../assets/printables/abc-uncolored/x.png';
import yUncolored from '../assets/printables/abc-uncolored/y.png';
import zUncolored from '../assets/printables/abc-uncolored/z.png';

export const PrintablesData = [
  {
    index: 0,
    id: 'ABC_COLORED',
    title: 'ABC Kids (Colored)',
    tag: 'all',
    image: ABC,
    description1: 'ABC Kids Drawings',
    imagesList: [
      aColored,
      bColored,
      cColored,
      dColored,
      eColored,
      fColored,
      gColored,
      hColored,
      iColored,
      jColored,
      kColored,
      lColored,
      mColored,
      nColored,
      oColored,
      pColored,
      qColored,
      rColored,
      sColored,
      tColored,
      uColored,
      vColored,
      wColored,
      xColored,
      yColored,
      zColored,
    ],
  },
  {
    index: 1,
    id: 'ABC_UN_COLORED',
    image: ABCUncolored,
    title: 'ABC Kids (Uncolored)',
    tag: 'coloring',
    description1: 'ABC Kids Drawings Uncolored',
    imagesList: [
      aUncolored,
      bUncolored,
      cUncolored,
      dUncolored,
      eUncolored,
      fUncolored,
      gUncolored,
      hUncolored,
      iUncolored,
      jUncolored,
      kUncolored,
      lUncolored,
      mUncolored,
      nUncolored,
      oUncolored,
      pUncolored,
      qUncolored,
      rUncolored,
      sUncolored,
      tUncolored,
      uUncolored,
      vUncolored,
      wUncolored,
      xUncolored,
      yUncolored,
      zUncolored,
    ],
  },
];
