export const addToCart = (product) => (dispatch) => {
  dispatch({ type: 'ADD_TO_CART', payload: product });
};

export const removeFromCart = (key) => (dispatch) => {
  dispatch({ type: 'REMOVE_FROM_CART', payload: key });
};

export const updateProductQuantity = (product) => (dispatch) => {
  dispatch({ type: 'UPDATE_PRODUCT_QUANTITY', payload: product });
};

export const clearCart = () => (dispatch) => {
  dispatch({ type: 'CLEAR_CART' });
};
