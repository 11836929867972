import React from 'react';
import gsap from 'gsap';
import Styles from './pageNotFound.module.scss';
import { Chuchu } from '../../components/svgAssets/chuchuCharacter';
import Pages from '../../assets/images/pages.png';
import Glasses from '../../assets/images/glasses.png';
import { Home } from '../../components/svgAssets/home';

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      glassesFound: false,
    };
  }

  findGlasses() {
    const timeline = gsap.timeline();

    timeline
      .to('#lostGlasses', {
        zIndex: 1000,
      })
      .to('#lostGlasses', {
        left: 'calc(50vw - 120px)',
        top: 'calc(50vh - 140px)',
        transform: 'rotate(25deg)',
        duration: 1,
        delay: 0.5,
      })
      .to('#findGlassesText', {
        opacity: 0,
        duration: 0.3,
        onComplete: async () => {
          await this.setState({ glassesFound: true });

          setTimeout(() => {
            timeline.to('#glassesFoundText', {
              opacity: 1,
              duration: 0.3,
            });
          }, 100);
        },
      });
  }

  updateCursor(e) {
    document
      .getElementById('mainContainer')
      .style.setProperty('--cursorX', `${e.clientX}px`);
    document
      .getElementById('mainContainer')
      .style.setProperty('--cursorY', `${e.clientY}px`);
  }

  render() {
    return (
      <div
        className={Styles.mainContainer}
        id="mainContainer"
        onMouseMove={this.updateCursor.bind(this)}>
        <Home styles={Styles.homeIcon} />
        {this.state.glassesFound ? (
          <div
            id="glassesFoundText"
            className={`${Styles.text} ${Styles.hiddenText}`}>
            <h3>Woohoo!! You're such a helping hand</h3>
            <h3>
              {'Thanks to you We are fixing things at '}
              <span className={Styles.yellow}>Chuchu HQ.</span>
            </h3>
            <h3>Stay tuned!</h3>
          </div>
        ) : (
          <div id="findGlassesText" className={Styles.text}>
            <h3>Oh no! looks like there is a problem with this page</h3>
            <h3>Help Chuchu find his glasses so he can fix this</h3>
          </div>
        )}
        <div
          id="lostGlasses"
          className={Styles.glasses}
          onClick={() => this.findGlasses()}>
          <img src={Glasses} alt="glasses" />
        </div>
        <div className={Styles.pages}>
          <img src={Pages} alt="pages" />
        </div>
        <div className={Styles.chuchu}>
          <Chuchu width="300" height="300" glasses={false} />
        </div>
      </div>
    );
  }
}

export { PageNotFound };
