import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './svgAssets.module.scss';
import { ClickSound } from '../../utils';

function Back(props) {
  return (
    <Link
      to={`/${props.link}`}
      onClick={() => {
        ClickSound();
      }}>
      <svg
        className={Styles.back}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 680 580">
        <g id="Layer 1">
          <path
            stroke={props.color ? props.color : '#fff'}
            strokeWidth="80"
            d="M433.033+26.3409L122.021+301.341L426.068+578.984"
            fill="#592d24"
            strokeLinecap="round"
            fillOpacity="0"
            opacity="1"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Link>
  );
}

export { Back };
