import React from 'react';

function Banner(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1082.047 155.278">
      <g id="Group_82" data-name="Group 82" transform="translate(-419 -80.315)">
        <path
          d="M2607.077,1271.174l20.6,45.161-20.6,49.857h141.047v-95.018Z"
          transform="translate(-2188.077 -1130.598)"
          fill={props.color}
        />
        <path
          d="M2607.077,1271.174l20.6,45.161-20.6,49.857h141.047v-95.018Z"
          transform="translate(-2188.077 -1130.598)"
          opacity="0.081"
        />
        <path
          d="M2748.124,1271.174l-20.6,45.161,20.6,49.857H2607.077v-95.018Z"
          transform="translate(-1247.077 -1130.598)"
          fill={props.color}
        />
        <path
          d="M2748.124,1271.174l-20.6,45.161,20.6,49.857H2607.077v-95.018Z"
          transform="translate(-1247.077 -1130.598)"
          opacity="0.075"
        />
        <path
          d="M2626.479,1279.541l66.708,34.758v-34.758Z"
          transform="translate(-2133.202 -1078.705)"
          fill={props.color}
        />
        <path
          d="M2626.479,1279.541l66.708,34.758v-34.758Z"
          transform="translate(-2133.202 -1078.705)"
          opacity="0.202"
        />
        <path
          d="M2693.187,1279.541l-66.708,34.758v-34.758Z"
          transform="translate(-1266.417 -1078.705)"
          fill={props.color}
        />
        <path
          d="M2693.187,1279.541l-66.708,34.758v-34.758Z"
          transform="translate(-1266.417 -1078.705)"
          opacity="0.197"
        />
        <path
          d="M2626.479,1262.28V1382.8H3560.2V1262.28Z"
          transform="translate(-2133.202 -1181.965)"
          fill={props.color}
        />
      </g>
      <text x="250" y="75" fontSize="3em" fill="#fff">
        This Month's Best Drawing
      </text>
    </svg>
  );
}

export { Banner };
