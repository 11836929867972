import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Back } from '../../../../components/svgAssets/back';
import Styles from './game.module.scss';
import GamesData from '../../../../data/games/whatsDifferent.json';
import { GameSuccessModal } from '../../../../components/gameSuccessModal';

function WhatsDifferentGame() {
  const params = useParams();
  const navigate = useNavigate();

  return <WhatsDifferentGameOriginal navigate={navigate} params={params} />;
}

class WhatsDifferentGameOriginal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameData: {},
      differencesFound: 0,
      totalDifferences: 0,
      gameCardSrcA: null,
      gameCardSrcB: null,
      foundDifferencesArray: [],
      success: false,
    };
  }

  async componentDidMount() {
    const gameData = GamesData.find(
      (game) => game.key === this.props.params.name,
    );

    if (!gameData) {
      this.props.navigate('/page-not-found');
    } else {
      document.title = `${gameData.title} | What's Different?`;

      const gameCardSrcA = require.context(
        '../../../../assets/gamesAssets/whatsDifferent',
        true,
        /.*/,
      )(`./${gameData.index}-a.svg`);
      const gameCardSrcB = require.context(
        '../../../../assets/gamesAssets/whatsDifferent',
        true,
        /.*/,
      )(`./${gameData.index}-b.svg`);

      await this.setState({
        totalDifferences: gameData.differences,
        gameData,
        gameCardSrcA,
        gameCardSrcB,
      });
    }
  }

  async differenceClick(index) {
    if (!this.state.foundDifferencesArray.includes(index)) {
      await this.setState({
        foundDifferencesArray: [...this.state.foundDifferencesArray, index],
        differencesFound: this.state.differencesFound + 1,
      });
    }

    if (this.state.differencesFound === this.state.totalDifferences) {
      this.setState({ success: true });
    }
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <h1 className={Styles.heading}>
          {this.props.params.name.charAt(0).toUpperCase()}
          {this.props.params.name.slice(1)} | What's Different?
        </h1>
        <Back link="game/what's-different" />
        <div className={Styles.scoreText}>
          <div className="d-flex justify-content-end">
            <p className="mr-4">Total Differences:</p>
            <p>{this.state.totalDifferences}</p>
          </div>
          <div className="d-flex justify-content-end">
            <p className="mr-4">Differences Found:</p>
            <p>{this.state.differencesFound}</p>
          </div>
        </div>
        <div className={Styles.cardsContainer}>
          <div className={Styles.gameCard}>
            <img src={this.state.gameCardSrcA} alt="left card" />
            <div className={Styles.differencesContainer}>
              {this.state.gameData.differencesArray?.map((diff, index) => (
                <p
                  key={`diff${index}`}
                  className={
                    this.state.foundDifferencesArray.includes(index)
                      ? Styles.clickedCircle
                      : null
                  }
                  style={{ left: diff.x, top: diff.y }}
                  onClick={() => this.differenceClick(index)}
                />
              ))}
            </div>
          </div>
          <div className={Styles.gameCard}>
            <img src={this.state.gameCardSrcB} alt="right card" />
            <div className={Styles.differencesContainer}>
              {this.state.gameData.differencesArray?.map((diff, index) => (
                <p
                  key={`diffs${index}`}
                  className={
                    this.state.foundDifferencesArray.includes(index)
                      ? Styles.clickedCircle
                      : null
                  }
                  style={{ left: diff.x, top: diff.y }}
                  onClick={() => this.differenceClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
        {this.state.success ? (
          <GameSuccessModal message={this.state.gameData.successMessage} />
        ) : null}
      </div>
    );
  }
}

export { WhatsDifferentGame };
