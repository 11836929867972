import React, { useEffect } from 'react';
import gsap from 'gsap';
import Styles from './alert.module.scss';

function Alert(props) {
  useEffect(() => {
    const alertTimeline = gsap.timeline();

    alertTimeline
      .to('#alertContainer', 0.5, { opacity: 1, top: 10, autoAlpha: 1 })
      .to('#alertContainer', 0.5, { opacity: 0, top: 5, autoAlpha: 1 }, '2');
  }, []);

  return (
    <div
      id="alertContainer"
      className={`${Styles.alertContainer} ${Styles[props.type]}`}>
      <p className="m-0">{props.text}</p>
    </div>
  );
}

export { Alert };
