import React from 'react';
import { Back } from '../svgAssets/back';
import { ThreeCircles } from '../svgAssets/threeCircles';
import Styles from './gameMainContainer.module.scss';

function GameMainContainer(props) {
  return (
    <div className={Styles.mainContainer}>
      <h1 className={Styles.heading}>{props.title}</h1>
      <Back link="games" />
      <ThreeCircles top={true} className="d-none d-sm-block" />
      <ThreeCircles left={true} />
      <div className={Styles.allGamesContainer}>{props.children}</div>
    </div>
  );
}

export { GameMainContainer };
