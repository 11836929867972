import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './games.module.scss';
import FindMyTwinIcon from '../../assets/gamesAssets/findMyTwin/icon.svg';
import WhatsDifferentIcon from '../../assets/gamesAssets/whatsDifferent/1-a.svg';
import { PuzzleIcons } from '../../components/svgAssets/puzzles';
import { Home } from '../../components/svgAssets/home';
import { ClickSound } from '../../utils';

class Games extends React.Component {
  render() {
    const categoryProps = {
      onClick: () => {
        ClickSound();
      },
      className: Styles.category,
    };

    return (
      <div className={Styles.mainContainer}>
        <h1 className={Styles.heading}>Games</h1>
        <Home />
        <PuzzleIcons top={true} />
        <PuzzleIcons left={true} />
        <div className={Styles.categoriesContainer}>
          <Link to="/game/find-my-twin" {...categoryProps}>
            <img src={FindMyTwinIcon} alt="find my twin" />
            <p>Find My Twin</p>
          </Link>
          <Link to="/game/what's-different" {...categoryProps}>
            <img
              src={WhatsDifferentIcon}
              alt="what's
						different"
              className={Styles.whatsDifferentIcon}
            />
            <p>What's Different?</p>
          </Link>
        </div>
      </div>
    );
  }
}

export { Games };
