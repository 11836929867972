import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './findMyTwin.module.scss';
import { GameMainContainer } from '../../../components/gameMainContainer';
import PlantIcon from '../../../assets/gamesAssets/findMyTwin/plant/0.svg';
import { ClickSound } from '../../../utils';
import FlowerIcon from '../../../assets/gamesAssets/findMyTwin/flower/0.svg';
import AnimalIcon from '../../../assets/gamesAssets/findMyTwin/animal/5.svg';
import CandyIcon from '../../../assets/gamesAssets/findMyTwin/candy/2.svg';

class FindMyTwinMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = { gameIndex: '' };
  }

  render() {
    const games = [
      {
        name: 'animal',
        icon: AnimalIcon,
      },
      {
        name: 'plant',
        icon: PlantIcon,
      },
      {
        name: 'candy',
        icon: CandyIcon,
      },
      {
        name: 'flower',
        icon: FlowerIcon,
      },
    ];

    return (
      <GameMainContainer title="Find My Twin">
        {games.map((game, index) => (
          <Link
            key={`FindMyTwinGame${index}`}
            to={`/game/find-my-twin/${game.name}`}
            onClick={() => {
              ClickSound();
            }}
            className={`d-flex justify-content-center ${Styles.gameItem}`}
            onMouseOver={() => {
              this.setState({ gameIndex: index });
            }}
            onMouseOut={() => {
              this.setState({ gameIndex: '' });
            }}>
            <img src={game.icon} alt={game.name} />
          </Link>
        ))}
      </GameMainContainer>
    );
  }
}

export { FindMyTwinMain };
