const initialState = { products: [] };
// eslint-disable-next-line
const user = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      let { products } = state;
      const cartItemIndex = state.products.findIndex(
        (cartItem) => cartItem.key === action.payload.key,
      );

      if (cartItemIndex >= 0) {
        products[cartItemIndex] = {
          ...action.payload,
          quantity: action.payload.quantity + products[cartItemIndex].quantity,
        };
      } else {
        products = [...state.products, action.payload];
      }

      return { products };
    }

    case 'REMOVE_FROM_CART': {
      return {
        products: state.products.filter(
          (product) => product.key !== action.payload,
        ),
      };
    }

    case 'UPDATE_PRODUCT_QUANTITY': {
      const { products } = state;
      const cartItemIndex = state.products.findIndex(
        (cartItem) => cartItem.key === action.payload.key,
      );

      if (cartItemIndex >= 0) {
        products[cartItemIndex] = {
          ...products[cartItemIndex],
          quantity: action.payload.quantity,
        };
      }

      return { products };
    }

    case 'CLEAR_CART': {
      return {
        products: [],
      };
    }

    default:
      return state;
  }
};

export default user;
