import React from 'react';
import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import Styles from './whatsDifferent.module.scss';
import { GameMainContainer } from '../../../components/gameMainContainer';
import GameOneIcon from '../../../assets/gamesAssets/whatsDifferent/1-a.svg';
import GameTwoIcon from '../../../assets/gamesAssets/whatsDifferent/2-a.svg';
import GameThreeIcon from '../../../assets/gamesAssets/whatsDifferent/3-a.svg';

class WhatsDifferentMain extends React.Component {
  render() {
    const games = [
      {
        name: 'zen',
        icon: GameOneIcon,
      },
      {
        name: 'bloom',
        icon: GameTwoIcon,
      },
      {
        name: 'night',
        icon: GameThreeIcon,
      },
    ];

    return (
      <GameMainContainer title="What's Different?">
        {games.map((game, index) => (
          <SwiperSlide
            className="d-flex justify-content-center"
            key={`What'sDifferentGame${index}`}>
            <Link
              to={`/game/what's-different/${game.name}`}
              className={Styles.gameItem}>
              <img
                src={game.icon}
                alt={game.name}
                className={Styles.gameIcon}
              />
            </Link>
          </SwiperSlide>
        ))}
      </GameMainContainer>
    );
  }
}

export { WhatsDifferentMain };
