/* eslint-disable */
import React from 'react';
import { DateTime } from 'luxon';
import gsap from 'gsap';
import { Home } from '../../components/svgAssets/home';
import Triangles from '../../assets/triangles.svg';
import TimeIcon from '../../assets/time-icon.svg';
import VenueIcon from '../../assets/venue-icon.svg';
import EventIcon from '../../assets/event-icon.svg';
import PastIcon from '../../assets/history.png';
import StallIcon from '../../assets/popup.png';
import Styles from './events.module.scss';
import EventsData from '../../data/events.json';

class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEvents: 'all',
      events: EventsData,
      showDetails: '',
    };
  }

  updateEvents(tag) {
    this.setState({ showEvents: tag });

    if (tag === 'all') {
      this.setState({ events: EventsData });
    } else {
      this.setState({
        events: EventsData.filter((event) =>
          tag === 'past'
            ? DateTime.fromFormat(
                `${event.date} ${event.time.toUpperCase()}`,
                'dd MMMM, yyyy hh:mm a',
              ) < DateTime.local()
            : DateTime.fromFormat(
                `${event.date} ${event.time.toUpperCase()}`,
                'dd MMMM, yyyy hh:mm a',
              ) >= DateTime.local(),
        ),
      });
    }
  }

  showEventDetails(event) {
    this.setState({ showDetails: event });

    gsap.to('#eventDetailsContainer', 0.3, { visibility: 'visible' });
    gsap.to('#eventDetailsContent', 0.3, {
      scale: 1,
      ease: 'back',
    });
  }

  hideEventDetails() {
    this.setState({ showDetails: '' });

    const timeline = gsap.timeline();

    timeline
      .to('#eventDetailsContent', 0.3, {
        scale: 0,
        ease: 'back',
      })
      .to('#eventDetailsContainer', 0, { visibility: 'hidden' });
  }

  render() {
    return (
      <div className={Styles.mainContainer}>
        <h1 className={Styles.heading}>Chuchu Events</h1>
        <Home color="#fabe37" />
        <img className={Styles.topTriangles} src={Triangles} alt="triangles" />
        <img
          className={Styles.bottomTriangles}
          src={Triangles}
          alt="triangles"
        />
        <div className={Styles.buttonsContainer}>
          <button
            onClick={() => {
              this.updateEvents('all');
            }}
            className={
              this.state.showEvents === 'all' ? Styles.activeButton : null
            }>
            All Events
          </button>
          <button
            onClick={() => {
              this.updateEvents('past');
            }}
            className={
              this.state.showEvents === 'past' ? Styles.activeButton : null
            }>
            Past Events
          </button>
          <button
            onClick={() => {
              this.updateEvents('upcoming');
            }}
            className={
              this.state.showEvents === 'upcoming' ? Styles.activeButton : null
            }>
            Upcoming Events
          </button>
        </div>
        <div className={Styles.eventsContainer}>
          {this.state.events?.length ? (
            this.state.events.map((event) => (
              <div
                key={event.index}
                className={Styles.eventCard}
                onClick={() => {
                  this.showEventDetails(event);
                }}>
                <div>
                  {DateTime.fromFormat(
                    `${event.date} ${event.time.toUpperCase()}`,
                    'dd MMMM, yyyy hh:mm a',
                  ) < DateTime.local() ? (
                    <img
                      className={Styles.pastIcon}
                      src={PastIcon}
                      alt="past"
                    />
                  ) : null}
                  <img
                    className={Styles.eventIcon}
                    src={event.type === 'stall' ? StallIcon : EventIcon}
                    alt="event"
                  />
                </div>
                <div>
                  <h5 className="mb-4">{event.title}</h5>
                  <div className="d-flex align-items-center">
                    <img
                      className={Styles.timeIcon}
                      src={TimeIcon}
                      alt="date"
                    />
                    <p className="m-0">
                      {event.dateDisplay} at {event.time}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      className={Styles.venueIcon}
                      src={VenueIcon}
                      alt="venue"
                    />
                    <p className="m-0">{event.venue}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p
              style={{
                margin: 50,
              }}>
              Oops! No events found.
            </p>
          )}
        </div>
        <div id="eventDetailsContainer" className={Styles.detailsModal}>
          <div id="eventDetailsContent" className={Styles.detailsContent}>
            <img
              className={Styles.starIcon}
              src={
                this.state.showDetails.type === 'stall' ? StallIcon : EventIcon
              }
              height="100"
              alt="event"
            />
            <h4 className="mt-3">{this.state.showDetails.title}</h4>
            <p className="m-0 text-muted">
              {this.state.showDetails.dateDisplay} at{' '}
              {this.state.showDetails.time}
            </p>
            <p className="m-0 text-muted">{this.state.showDetails.venue}</p>
            <p className="mt-3">{this.state.showDetails.description1}</p>
            <p>{this.state.showDetails.description2}</p>
            <p>{this.state.showDetails.description3}</p>
            <button
              className={Styles.closeButton}
              onClick={() => {
                this.hideEventDetails();
              }}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export { Events };
