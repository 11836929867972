import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Styles from './svgAssets.module.scss';

function GoToDashboardButton() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('#goToDashboardButton', {
      rotation: +360,
      scrollTrigger: {
        scrub: 5,
      },
    });
  }, []);

  return (
    <svg
      id="goToDashboardButton"
      className={Styles.dashboardButton}
      viewBox="0 0 500 500"
      data-duration="5">
      <defs>
        <filter id="textcircle1" x="0" y="0" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodOpacity="0.141" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <path
        id="textcircle"
        fill="#fabe37"
        stroke="none"
        strokeWidth="5"
        data-duration="5"
        filter="url(#textcircle1)"
        d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250"></path>
      <text dy="75">
        <textPath xlinkHref="#textcircle">
          <tspan>·</tspan>
          GO
          <tspan>·</tspan>
          TO
          <tspan>·</tspan>
          DASHBOARD
        </textPath>
      </text>
    </svg>
  );
}

export { GoToDashboardButton };
