import React from 'react';
import Styles from './svgAssets.module.scss';

function PuzzleIcons(props) {
  return (
    <svg
      className={`${Styles.puzzles} ${props.top ? Styles.top : Styles.bottom} ${
        props.left ? Styles.left : Styles.right
      }`}
      xmlns="http://www.w3.org/2000/svg"
      height="90px"
      width="270px"
      viewBox="0 0 270 60">
      <defs>
        <filter
          id="Union_2"
          x="96"
          y="10"
          width="73"
          height="63"
          filterUnits="userSpaceOnUse">
          <feOffset dx="10" dy="10" input="SourceAlpha" />
          <feGaussianBlur result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Union_3"
          x="191"
          y="0"
          width="63"
          height="73"
          filterUnits="userSpaceOnUse">
          <feOffset dx="10" dy="10" input="SourceAlpha" />
          <feGaussianBlur result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Union_1"
          x="0"
          y="10"
          width="73"
          height="63"
          filterUnits="userSpaceOnUse">
          <feOffset dx="10" dy="10" input="SourceAlpha" />
          <feGaussianBlur result="blur-3" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_84" data-name="Group 84" transform="translate(-1383 -54)">
        <g transform="matrix(1, 0, 0, 1, 1383, 54)" filter="url(#Union_2)">
          <path
            id="Union_2-2"
            data-name="Union 2"
            d="M6,53a5.978,5.978,0,0,1-6-5.955V32.615s11,3.69,11.3-5.863S0,21.059,0,21.059V5.955A5.977,5.977,0,0,1,6,0H21.64s-4.417,11.478,5.081,11.343S31.707,0,31.707,0H46.8a5.978,5.978,0,0,1,6,5.955V18.513a7.146,7.146,0,1,1,0,13V47.045A5.978,5.978,0,0,1,46.8,53Z"
            transform="translate(96 10)"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 1383, 54)" filter="url(#Union_3)">
          <path
            id="Union_3-2"
            data-name="Union 3"
            d="M5.955,63A5.978,5.978,0,0,1,0,57V41.359s11.478,4.417,11.343-5.081S0,31.292,0,31.292V16.2a5.977,5.977,0,0,1,5.955-6H18.513a7.146,7.146,0,1,1,13,0H47.045A5.978,5.978,0,0,1,53,16.2V57a5.978,5.978,0,0,1-5.955,6Z"
            transform="translate(191)"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1, 0, 0, 1, 1383, 54)" filter="url(#Union_1)">
          <path
            id="Union_1-2"
            data-name="Union 1"
            d="M6,53a5.978,5.978,0,0,1-6-5.955V5.955A5.978,5.978,0,0,1,6,0h15.64s-4.417,11.478,5.08,11.343S31.708,0,31.708,0H46.8a5.978,5.978,0,0,1,6,5.955V18.513a7.146,7.146,0,1,1,0,13V47.045A5.978,5.978,0,0,1,46.8,53Z"
            transform="translate(0 10)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export { PuzzleIcons };
