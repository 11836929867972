/* eslint-disable */
import React, { Component } from 'react';
import FontAwesomeIcon from 'react-fontawesome';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import Styles from './checkout.module.scss';
import { Alert } from '../../../components/alert';
import { Loading } from '../../../components/loading';

function CheckoutScreen() {
  const navigate = useNavigate();
  const cartItems = useLocation()?.state?.cartItems;

  return <CheckoutScreenComponent navigate={navigate} cart={cartItems} />;
}

class CheckoutScreenComponent extends Component {
  constructor(props) {
    super(props);

    this.name = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.address = React.createRef();
    this.message = React.createRef();

    this.state = {
      error: '',
      showLoading: '',
      paymentMethod: 'COD',
      alert: {
        type: '',
        text: '',
        show: false,
      },
      orderPlaced: false,
      orderID: '',
    };
  }

  componentDidMount() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');

    this.setState({
      orderID: `TYC-${dd}${mm}-${uuidv4()
        .toString()
        .padStart(4, '0')
        .slice(0, 5)
        .toUpperCase()}`,
    });
  }

  placeOrder(e) {
    e.stopPropagation();
    e.preventDefault();

    if (
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.current.value,
      ) === false
    ) {
      this.setState({ error: 'Please enter a valid email' });

      this.email.current.value = '';
    } else if (/^(([0-9]{7,11}))$/.test(this.phone.current.value) === false) {
      this.setState({ error: 'Please enter a valid contact number' });

      this.phone.current.value = '';
    } else if (!this.address.current.value) {
      this.setState({ error: 'Please enter a valid address' });

      this.address.current.value = '';
    } else {
      this.setState({
        error: '',
        showLoading: true,
      });

      let cartItems = '';
      const orderTotal = this.props.cart.products.reduce(
        (n, item) =>
          n + parseFloat(item.salePrice || item.price) * item.quantity,
        0,
      );

      this.props.cart.products.forEach((item) => {
        cartItems = `${cartItems ? `${cartItems}` : ''}${item.quantity} x ${
          item.title
        } (${item.salePrice ? 'Sale ' : ''}Rs.${
          item.salePrice || item.price
        }) \n`;
      });

      const date = new Date().toLocaleString('en-PK');

      const orderParams = {
        orderID: this.state.orderID,
        datetime: date,
        name: this.name.current.value,
        email: this.email.current.value,
        contact: this.phone.current.value,
        address: this.address.current.value,
        message: this.message.current.value,
        payment: this.state.paymentMethod,
        items: cartItems,
        orderTotal,
        shipping: 200,
        total: orderTotal + 200,
        status: 'PENDING_APPROVAL',
      };

      axios
        .post(process.env.REACT_APP_ORDERS_SHEET_DB, orderParams, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_SHEETDB_TOKEN}`,
          },
        })
        .then(() => {
          this.props.navigate('/order-success', {
            state: {
              orderID: this.state.orderID,
              paymentMethod: this.state.paymentMethod,
            },
          });

          const message = `&text=New order Placed by *${
            this.name.current.value
          }* %0D%0A
				%0D%0AOrder ID:  ${orderParams.orderID}%0D%0A
				%0D%0AOrder Date:  ${date}%0D%0A
				%0D%0A%F0%9F%93%9E:  ${this.phone.current.value}
				%0D%0A%E2%9C%89:  *${this.email.current.value}*
				%0D%0A%F0%9F%8F%A0	:  ${this.address.current.value}

				%0D%0A%F0%9F%92%B0	:  *${
          this.props.cart.products.reduce(
            (n, item) =>
              n + parseFloat(item.salePrice || item.price) * item.quantity,
            0,
          ) + 200
        } Rs*
				%0D%0A
				%0D%0APayment Method:  *${orderParams.payment}*
				%0D%0A
				%0D%0AOrder Items: %0D%0A ${this.props.cart.products.map((item) => {
          return `%0D%0A     ${item.quantity} - ${item.title} (${
            item.salePrice ? '* Sale * ' : ''
          }Rs.${item.salePrice || item.price})`;
        })}`;

          axios.post(`${process.env.REACT_APP_SMS_ALERT_URL}?
					phone=${process.env.REACT_APP_SMS_ALERT_NUMBER}
					&apikey=${process.env.REACT_APP_SMS_ALERT_API_KEY}
					${message}
				`);

          axios.post(`${process.env.REACT_APP_SMS_ALERT_URL}?
					phone=${process.env.REACT_APP_SMS_ALERT_NUMBER_2}
					&apikey=${process.env.REACT_APP_SMS_ALERT_API_KEY_2}
					${message}
				`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const productAssets = require.context(
      '../../../assets/shopAssets',
      true,
      /.*/,
    );

    return this.props.cart?.products.length ? (
      <div className={Styles.mainContainer}>
        {this.state.alert.show ? (
          <Alert text={this.state.alert.text} type={this.state.alert.type} />
        ) : null}
        {this.state.showLoading ? <Loading /> : null}
        <Link to="/cart" className={Styles.cartLink}>
          <FontAwesomeIcon
            name="chevron-left"
            style={{ marginRight: '10px' }}
          />{' '}
          CART
        </Link>
        <h3 className={Styles.mainHeading}>CHECKOUT</h3>
        <div className={Styles.orderInfoContainer}>
          <form
            className={Styles.inputsContainer}
            onSubmit={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}>
            <p className={Styles.sectionHeading}>SHIPPING INFORMATION</p>
            <hr className={Styles.line} />
            <input
              ref={this.name}
              className={Styles.name}
              placeholder="Full Name"
              type="text"
              required
            />
            <input
              ref={this.email}
              className={Styles.email}
              placeholder="Email address"
              type="email"
              required
            />
            <input
              ref={this.phone}
              className={Styles.phone}
              placeholder="Phone number"
              type="text"
              required
            />
            <textarea
              ref={this.address}
              className={Styles.address}
              placeholder="Complete Shipping address"
              type="text"
              required
            />
            <p className={Styles.paymentMethodHeading}>Payment Method:</p>
            <div className={Styles.paymentMethodContainer}>
              <div
                onClick={() => this.setState({ paymentMethod: 'COD' })}
                className={
                  this.state.paymentMethod === 'COD'
                    ? Styles.selectedMethod
                    : null
                }>
                Cash On Delivery
              </div>
              <div
                onClick={() => this.setState({ paymentMethod: 'JAZZCASH' })}
                className={
                  this.state.paymentMethod === 'JAZZCASH'
                    ? Styles.selectedMethod
                    : null
                }>
                Jazz Cash
              </div>
              <div
                onClick={() =>
                  this.setState({ paymentMethod: 'BANK TRANSFER' })
                }
                className={
                  this.state.paymentMethod === 'BANK TRANSFER'
                    ? Styles.selectedMethod
                    : null
                }>
                Bank Transfer
              </div>
            </div>
            {this.state.paymentMethod === 'JAZZCASH' ||
            this.state.paymentMethod === 'BANK TRANSFER' ? (
              <p className={Styles.paymentMethodInfo}>
                {
                  'Kindly send your payment screenshot to the following Whatsapp number to confirm your order: 0325-1779407'
                }
              </p>
            ) : null}
            {this.state.paymentMethod === 'JAZZCASH' ? (
              <div className="mt-2 text-left">
                <p className={Styles.paymentMethodHeading}>
                  Jazz Cash Details:
                </p>
                <div className={Styles.accountDetails}>
                  <p className={Styles.accountInfo}>Mobile Number: </p>
                  <p className={Styles.accountInfo}>0325-1779407</p>
                </div>
              </div>
            ) : null}
            {this.state.paymentMethod === 'BANK TRANSFER' ? (
              <div className="mt-2 text-left">
                <p className={Styles.paymentMethodHeading}>
                  Bank Account Details:
                </p>
                <div className={Styles.accountDetails}>
                  <p className={Styles.accountInfo}>Bank: </p>
                  <p className={Styles.accountInfo}>Askari Bank</p>
                </div>
                <div className={Styles.accountDetails}>
                  <p className={Styles.accountInfo}>Account Number: </p>
                  <p className={Styles.accountInfo}>0705-0200-0123-00</p>
                </div>
              </div>
            ) : null}
            <textarea
              ref={this.message}
              className={Styles.message}
              placeholder="Order Notes (Optional)"
            />
            <p
              className={
                this.state.error ? Styles.errorShown : Styles.errorHidden
              }>
              {this.state.error}
            </p>
            <button
              className={Styles.confirmBtn}
              type="submit"
              onClick={(e) => this.placeOrder(e)}>
              Place Order
            </button>
          </form>
          <div className={Styles.cartItemsContainer}>
            <p className={Styles.sectionHeading}>ORDER SUMMARY</p>
            <hr className={Styles.line} />
            {this.props.cart.products.length ? (
              <>
                {this.props.cart.products.map((cartItem) => (
                  <div key={cartItem.key} className={Styles.cartItem}>
                    <div className="d-flex">
                      <img
                        src={productAssets(`./${cartItem.key}/0.jpg`)}
                        alt="product"
                      />
                      <div className={Styles.productTextContainer}>
                        <div className={Styles.productInfo}>
                          <p className={Styles.itemTitle}>{cartItem.title}</p>
                          <p className={Styles.itemQuantity}>
                            x {cartItem.quantity}
                          </p>
                        </div>
                        {cartItem.salePrice ? (
                          <p className={Styles.salePrice}>
                            Rs.{cartItem.salePrice}{' '}
                            <span>Rs.{cartItem.price}</span>
                          </p>
                        ) : (
                          <p className={Styles.price}>Rs.{cartItem.price}</p>
                        )}
                      </div>
                    </div>
                    <p className="">
                      Rs.{' '}
                      {parseFloat(cartItem.salePrice || cartItem.price) *
                        cartItem.quantity}
                    </p>
                  </div>
                ))}
                <div className={Styles.chargesContainer}>
                  <hr className={Styles.line} />
                  <div className="d-flex align-items-center">
                    <p className="m-0">Order total:</p>
                    <p className={`p-0 m-0 ${Styles.chargesPrice}`}>
                      Rs.{' '}
                      {this.props.cart.products.reduce(
                        (n, item) =>
                          n +
                          parseFloat(item.salePrice || item.price) *
                            item.quantity,
                        0,
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="m-0">Shipping charges:</p>
                    <p className={`m-0 ${Styles.chargesPrice}`}>Rs. 200</p>
                  </div>
                  <hr className={Styles.line} />
                  <div
                    className={`d-flex align-items-center ${Styles.totalPrice}`}>
                    <p>Total:</p>
                    <p className={`p-0 ${Styles.chargesPrice}`}>
                      Rs.{' '}
                      {this.props.cart.products.reduce(
                        (n, item) =>
                          n +
                          parseFloat(item.salePrice || item.price) *
                            item.quantity,
                        0,
                      ) + 200}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <p className="text-muted p-5 mt-5">
                There are no items in your cart
              </p>
            )}
          </div>
        </div>
      </div>
    ) : (
      <Navigate to="/cart" replace />
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const Checkout = connect(mapStateToProps, null)(CheckoutScreen);

export { Checkout };
